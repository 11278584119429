/**
 * Footer Module
 * Has methods related to composition of the footer module
 */

// import { accordionParser } from 'partials/accordion';
import { Disclaimer } from 'partials/disclaimer';
import { customEventDispatcher, scrollTo, renderer, Tabbables } from 'utils';
import { EVENTS, CUSTOM_EVENTS, KEYBOARD } from 'Constants';
import FooterButtonTemplate from './FooterButtonTemplate';

const CLASSES = {
    POPUP_LINKS: 'legal__link--popup',
    GLOBAL_NAVIGATION_STATE: 'global-navigation-state',
    DOCKED_FOOTER_BUTTON: 'docked-footer__button',
    FOOTER_ACTIVE: 'footer--active',
    HIDDEN: 'hidden',
    DISCLAIMER: 'footer-disclaimer',
    GLOBAL_HEADER: 'global-header',
    HEADER: 'header',
    FOOTER: 'footer'
};

const variantInfo = window.mbVans.ns('pageData').variantInfo;

let footerOpen = false;

/**
 * @function
 * @name legalLinkClickHandler
 * @description Click handler for legal links in footer
 * @param {Object} event - Event object from event attach
 */
const legalLinkClickHandler = (event) => {
    event.preventDefault();
    window.open(event.target.href, event.target.innerText, 'left=20,top=20,toolbars=0,scrollbars=1,location=0,statusbars=0,menubars=1,resizable=0,width=440,height=550');
};

export default class Footer {
    constructor(element) {
        this.element = element;
        this.onToggleFooterTrigger = this.onToggleFooterTrigger.bind(this);
        this.showFooterButton = this.showFooterButton.bind(this);
        this.hideFooterButton = this.hideFooterButton.bind(this);
        this.onDisclaimerTrigger = this.onDisclaimerTrigger.bind(this);
        this.onDockedHeaderClick = this.onDockedHeaderClick.bind(this);
        this.onKeydownHandler = this.onKeydownHandler.bind(this);
        this.init();
    }

    /**
     * Transforms the footer DOM nodes into the one required by accordion
     */
    init() {
        this.cacheDOM();
        this.tabbables = new Tabbables(this.element);
        this.dockedState = this.globalNavState && this.globalNavState.getAttribute('data-global-navigation-behavior') === 'docked';
        if (this.dockedState && !variantInfo.isDDTVariant) {
            this.addFooterBtn();
            this.tabbables.saveTabIndices();
            this.tabbables.disableTabbables();
        }
        this.renderPageFooterDisclaimers(this.pageFooterDisclaimers);
        this.attachEvents();
        // if (this.accordionElement) {
        //     this.accordion = accordionParser.createAccordion({ element: this.accordionElement });
        // }

        return {
            disclaimer: new Disclaimer(this.element)
        };
    }

    /**
     * Cache DOM
     */
    cacheDOM() {
        this.accordionElement = this.element.querySelector('[data-accordion]');
        this.legalLinks = [].slice.call(this.element.querySelectorAll(`.${CLASSES.POPUP_LINKS}`));
        this.footer = document.querySelector(`.${CLASSES.FOOTER}`);
        this.header = document.querySelector(`.${CLASSES.HEADER}`) ||
            document.querySelector(`.${CLASSES.GLOBAL_HEADER}`);
        this.globalNavState = document.querySelector(`.${CLASSES.GLOBAL_NAVIGATION_STATE}`);
        this.pageFooterDisclaimers = window.mbVans.ns('pageData').pageFooterDisclaimers;
    }
    /**
     * Render footer disclaimers
     */
    renderPageFooterDisclaimers(footerdisclaimerData) {
        let footerDisclaimerHtml = '';
        footerdisclaimerData.forEach((disclaimer) => {
            const disclaimerHtml = `<dt class="disclaimer__legend ${disclaimer.error ? 'disclaimer--error' : ''} ${disclaimer.legend === 'NA' ? 'offscreen' : ''}"
                            data-legend="${disclaimer.legend}">
                            ${disclaimer.legend}
                        </dt>
                        <dd class="disclaimer__text ${disclaimer.error ? 'disclaimer--error' : ''}"
                            data-legend="${disclaimer.legend}"
                            tabindex="-1">
                            ${disclaimer.disclaimerText}
                        </dd>`;
            footerDisclaimerHtml += disclaimerHtml;
        });
        document.querySelectorAll('.disclaimer__drawer .disclaimer__note')[0].innerHTML = footerDisclaimerHtml;
    }
    /**
     * Attach event listeners
     */
    attachEvents() {
        this.legalLinks.map((legalLink) => {
            legalLink.addEventListener(EVENTS.CLICK, legalLinkClickHandler.bind(this));
            return legalLink;
        });

        if (this.dockedState && !variantInfo.isDDTVariant) {
            customEventDispatcher.addEventListener(
                CUSTOM_EVENTS.DOCKED_FOOTER.SHOW_FOOTER_BUTTON,
                this.showFooterButton
            );

            customEventDispatcher.addEventListener(
                CUSTOM_EVENTS.DOCKED_FOOTER.HIDE_FOOTER_BUTTON,
                this.hideFooterButton
            );

            // Dispatch event for docked footer button when clicked
            this.dockedFooterButton.addEventListener(EVENTS.CLICK,
                this.onDockedFooterButtonClick
            );

            this.header.addEventListener(EVENTS.CLICK, this.onDockedHeaderClick);

            document.body.addEventListener(EVENTS.KEYDOWN, this.onKeydownHandler);
        }

        if (this.dockedState) {
            // listener to show disclaimers when ddtvariant
            customEventDispatcher.addEventListener(
                CUSTOM_EVENTS.DOCKED_FOOTER.OPEN_FROM_DISCLAIMER,
                this.onDisclaimerTrigger
            );
            customEventDispatcher.addEventListener(
                CUSTOM_EVENTS.DOCKED_FOOTER.TOGGLE_TRIGGER,
                this.onToggleFooterTrigger
            );
        }
    }

    /**
     * @method addFooterBtn()
     * @description add footer button for docked mode
     */
    addFooterBtn() {
        this.dockedFooterButton = FooterButtonTemplate(
            window.mbVans.ns('pageData').localization
        )({ getNode: true });
        renderer.insertAdjacentElement(
            this.dockedFooterButton,
            this.element,
            renderer.POSITIONS.BEFORE_BEGIN
        );
    }

    /**
    * onDockedFooterButtonClick()
    * when docked footer button click dispatch footer toggle
    */
    onDockedFooterButtonClick() {
        customEventDispatcher.dispatchEvent(
            customEventDispatcher.createCustomEvent(
                CUSTOM_EVENTS.DOCKED_FOOTER.TOGGLE_TRIGGER
            )
        );
    }

    /**
    * showFooterButtonDisclaimer()
    * show docked footer button when disclaimer
    */
    showFooterButtonDisclaimer() {
        this.showFooterButton();
        this.dockedFooterButton.classList.add(CLASSES.DISCLAIMER);
    }

    /**
     * showFooterButton()
     * show docked footer button
     */
    showFooterButton() {
        this.dockedFooterButton.classList.remove(CLASSES.HIDDEN);
        this.dockedFooterButton.removeAttribute('tabindex');
    }

    /**
     * hideFooterButton()
     * hide docked footer button
     */
    hideFooterButton() {
        this.dockedFooterButton.classList.add(CLASSES.HIDDEN);
        this.dockedFooterButton.setAttribute('tabindex', '-1');
    }

    /**
     * onDisclaimerTrigger()
     * when clicking on disclaimers
     */
    onDisclaimerTrigger({ detail }) {
        const keepFooterButtonVisible = !(detail ? detail.keepFooterButtonVisible : false) && !variantInfo.isDDTVariant;
        if (keepFooterButtonVisible) {
            this.showFooterButtonDisclaimer();
        }
        this.onToggleFooterTrigger();
        this.dispatchDisclaimerOpen();
    }

    /**
     * @method onDockedHeaderClick
     * @description close the footer if a user clicks on the docked header
     *              while the footer is open
     */
    onDockedHeaderClick() {
        if ((event.target !== event.currentTarget) && footerOpen) {
            this.onToggleFooterTrigger();
        }
    }

    /**
     * dispatchDisclaimerOpen()
     * open disclaimer drawer
     */
    dispatchDisclaimerOpen() {
        if (footerOpen) {
            customEventDispatcher.dispatchEvent(
                customEventDispatcher.createCustomEvent(
                    CUSTOM_EVENTS.DOCKED_FOOTER.OPEN_DISCLAIMER
                )
            );
        }
    }

    /**
     * onToggleFooterTrigger()
     * show or hide docked footer
     */
    onToggleFooterTrigger() {
        footerOpen = !footerOpen;
        this.element.classList.toggle(CLASSES.FOOTER_ACTIVE, footerOpen);
        if (!variantInfo.isDDTVariant) {
            this.dockedFooterButton.classList[footerOpen ? 'add' : 'remove'](`${CLASSES.DOCKED_FOOTER_BUTTON}--open`);
            this.dockedFooterButton.dataset.analyticsTrigger = footerOpen ? 'footer-open' : '';

            if (this.dockedFooterButton.classList.contains(CLASSES.DISCLAIMER) && !footerOpen) {
                this.hideFooterButton();
                this.dockedFooterButton.classList.remove(CLASSES.DISCLAIMER);
            }
        }
        if (footerOpen) {
            this.tabbables.enableTabbables();
            customEventDispatcher.dispatchEvent(
                customEventDispatcher.createCustomEvent(CUSTOM_EVENTS.DOCKED_FOOTER.OPEN)
            );
        } else {
            this.tabbables.disableTabbables();
            customEventDispatcher.dispatchEvent(
                customEventDispatcher.createCustomEvent(CUSTOM_EVENTS.DOCKED_FOOTER.CLOSE)
            );
            scrollTo(0, 0, 'vertical', this.footer);
            customEventDispatcher.dispatchEvent(
                customEventDispatcher.createCustomEvent(
                    CUSTOM_EVENTS.DOCKED_FOOTER.CLOSE_DISCLAIMER
                )
            );
        }
    }

    /**
     * Keydown handler
     * Traps focus within footer
     */
    onKeydownHandler(e) {
        if (this.footer && footerOpen) {
            // A list of focusable elements
            const listFocusables = this.tabbables.allTabbables;

            // Tab key, when tabbing within the footer
            if (e.keyCode === KEYBOARD.TAB &&
                (listFocusables.indexOf(e.target) >= 0 || this.dockedFooterButton === e.target)) {
                // When shift+tab from the first element, send focus to the last focusable element in footer
                if (e.shiftKey) {
                    if (e.target === this.dockedFooterButton) {
                        listFocusables[listFocusables.length - 1].focus();
                        e.preventDefault();
                    }
                } else if (e.target === listFocusables[listFocusables.length - 1]) {
                    // When focused on last element, send focus to first element
                    this.dockedFooterButton.focus();
                    e.preventDefault();
                }
            }

            // When focused outside of footer, send focus to first element
            if (e.keyCode === KEYBOARD.TAB &&
                (listFocusables.indexOf(e.target) === -1 && e.target !== this.dockedFooterButton)) {
                e.preventDefault();
                this.dockedFooterButton.focus();
            }
        }
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

const EVENTS = {
    CLICK: 'click'
};

const CLASSES = {
    ACTIVE: 'accordion__active',
    ITEM: 'accordion__item',
    ACC_ELE: 'accordion--link-list',
    ACC_HEADING: 'accordion__item-heading',
    ACC_BUTTON: 'accordion__button',
    PANE: 'accordion__pane'

};

/**
 * @const ARIA_ATTRIBUTES
 * @description Collection of constant values for related aria attributes of the module
 * @type {{CONTROLS: string, EXPANDED: string, HIDDEN: string}}
 */
const ARIA_ATTRIBUTES = {
    CONTROLS: 'aria-controls',
    EXPANDED: 'aria-expanded',
    HIDDEN: 'aria-hidden'
};

export default class AccordionLinkList {
    constructor() {
        this.allAccELe = document.querySelectorAll(`.${CLASSES.ACC_ELE}`);
        this.handleClick = this.handleClick.bind(this);
        this.init();
    }

    init() {
        if (this.allAccELe) {
            this.allAccELe.forEach((acc, index) => {
                acc.addEventListener(EVENTS.CLICK, (e) => {
                    const clickedEle = e.target;
                    if (clickedEle.classList.contains(CLASSES.ACC_HEADING)) {
                        const accButton = clickedEle.querySelector(`.${CLASSES.ACC_BUTTON}`);
                        const accItem = clickedEle.parentNode;
                        const accPane = accItem.querySelector(`.${CLASSES.PANE}`);

                        this.handleClick(accItem, accButton, accPane, index);
                    }
                    return null;
                });
            });
        }
    }

    handleClick = (accItem, accButton, accPane) => {
        accItem.classList.toggle(CLASSES.ACTIVE);
        if (accItem.classList.contains(CLASSES.ACTIVE)) {
            accButton.setAttribute(ARIA_ATTRIBUTES.EXPANDED, 'true');
            accPane.setAttribute(ARIA_ATTRIBUTES.HIDDEN, 'false');
        } else {
            accButton.setAttribute(ARIA_ATTRIBUTES.EXPANDED, 'false');
            accPane.setAttribute(ARIA_ATTRIBUTES.HIDDEN, 'true');
        }
    };
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

import SubscribeSuccessTemplate from './templates/SubscribeSuccessTemplate';
import SubscribeExistsTemplate from './templates/SubscribeExistsTemplate';
import SubscribeErrorTemplate from './templates/SubscribeErrorTemplate';
import SubscribeModalTemplate from './templates/SubscribeModalTemplate';
import SubscribeModalSuccessTemplate from './templates/SubscribeModalSuccessTemplate';

export {
    SubscribeSuccessTemplate,
    SubscribeExistsTemplate,
    SubscribeErrorTemplate,
    SubscribeModalTemplate,
    SubscribeModalSuccessTemplate,
};

export default {
    SubscribeSuccessTemplate,
    SubscribeExistsTemplate,
    SubscribeErrorTemplate,
    SubscribeModalTemplate,
    SubscribeModalSuccessTemplate,
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

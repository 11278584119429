import { EVENTS, CUSTOM_EVENTS, DELAY } from 'Constants';
import { ClickOutside, customEventDispatcher, screen, Tabbables } from 'utils';
import Carousel from './Carousel';

const CLASSES = {
    BODY_OPEN: 'open',
    TOP_NAV: 'top-nav',
    SUB_NAV: 'sub-nav',
    CAROUSEL_CONTAINER: 'top-nav__vehicle-container--main-content',
    MENU_OPEN: 'top-nav__item--open',
    NAV_CONTAINER_OPEN: 'top-nav--open',
    OWNERS_MENU_OPEN: 'top-nav__item--owners-open',
    SHOPPING_TOOLS_MENU_TOGGLE: 'top-nav__link_shopping-tools',
    ACCOUNT_MENU_TOGGLE: 'top-nav__link_account',
    VEHICLES_MENU_TOGGLE: 'top-nav__link_vehicles',
    VEHICLES_SUB_MENU: 'top-nav__vehicle-container',
    VEHICLES_SUB_MENU_ACTIVE: 'top-nav__vehicle-container--main-content-active',
    VEHICLES_SUB_MENU_ITEMS: 'top-nav__link_parent',
    VEHICLES_SUB_MENU_ITEM: 'top-nav__item',
    VEHICLES_SUB_MENU_ITEM_ACTIVE: 'top-nav__item--active',
    VEHICLES_SUB_MENU_CONTAINER: 'top-nav__level',
    VEHICLES_SUB_MENU_CONTAINER_HIDDEN: 'top-nav__level--hidden',
    OWNERS_MENU_TOGGLE: 'top-nav__link_owners',
    OWNERS_MENU_TOGGLE_PARENT: 'top-nav__item--owners',
    OWNERS_MENU_CONTAINER: 'top-nav__owners-container',
    VEHICLES_SUB_MENU_MODEL_LINKS: 'top-nav__sublink--model-link',
    VEHICLES_SUB_MENU_MODEL_CONTAINER: 'top-nav__item--model-dropdown',
    VEHICLES_SUB_MENU_MODEL_CONTAINER_OUTER: 'top-nav__item--model-list',
    VEHICLES_SUB_MENU_MODEL_ACTIVE: 'top-nav__item--model-active',
    HEADER_DOCKING: 'header--docking-state',
    SPECIALTY_PAGE: 'specialty-page',
    LOCKED: 'locked'
};

const ATTRIBUTES = {
    VEHICLES_BODYSTYLE_SELECTED: 'data-bodystyle-selected',
    DATA_OPEN: 'data-open',
    DATA_SUBMENU_STATE: 'data-submenu-state',
    DATA_MENU_CLASS: 'data-menu-class',
    DATA_MENU_ITEM: 'data-menu-item',
    ARIA_HIDDEN: 'aria-hidden'
};

export default class LargeNav {
    constructor(element) {
        this.element = element;
        this.currentIndex = null;
        this.privateCarousel = null;
        this.menuTriggered = null;
        this.ownersMenuLink = null;
        this.clickOutsideMenus = [];
        this.init();
        if (document.body.classList.contains(CLASSES.SPECIALTY_PAGE)) {
            this.specialtyPageState = {
                dockingState: false,
                enableSubmenus: false,
            };
        }
    }

    cacheDOM() {
        this.mainMenuLinks = document.querySelectorAll(`.${CLASSES.VEHICLES_MENU_TOGGLE}, .${CLASSES.SHOPPING_TOOLS_MENU_TOGGLE}, .${CLASSES.ACCOUNT_MENU_TOGGLE}`);
        this.vehicleMenuLinks = document.querySelector(`.${CLASSES.VEHICLES_SUB_MENU}`);
        this.ownersMenuLink = document.querySelector(`.${CLASSES.OWNERS_MENU_TOGGLE}`);
        this.carouselContainer = document.querySelector(`.${CLASSES.CAROUSEL_CONTAINER}`);
        this.modelMenuLinks = document.querySelectorAll(`.${CLASSES.VEHICLES_SUB_MENU_MODEL_LINKS}`);
        this.modelMenuContainerOuters = document.querySelectorAll(`.${CLASSES.VEHICLES_SUB_MENU_MODEL_CONTAINER_OUTER}`);
        this.topNav = document.querySelector(`.${CLASSES.TOP_NAV}`);
        this.subNav = document.querySelector(`.${CLASSES.SUB_NAV}`);
    }

    attachEvents() {
        [].slice.call(this.mainMenuLinks).forEach((mainMenuLink) => {
            // instantiate a ClickOutside for each mainMenuLinks
            this.clickOutsideMenus.push(
                new ClickOutside(
                    mainMenuLink,
                    this.onMainMenuClick.bind(this),
                    this.onMainMenuClose.bind(this),
                    [this.subNav, mainMenuLink.parentNode]
                )
            );
            mainMenuLink.setAttribute(ATTRIBUTES.DATA_OPEN, 'false');
        });

        this.vehicleMenuLinks.addEventListener(EVENTS.CLICK, (e) => {
            this.vehicleMenuListener(e);
        });

        if (this.ownersMenuLink) {
            // instantiate a ClickOutside for the ownersMenuLink
            this.clickOutsideMenus.push(
                new ClickOutside(
                    this.ownersMenuLink,
                    this.onOwnerMenuClick.bind(this),
                    this.onOwnerMenuClose.bind(this),
                    this.ownersMenuLink.parentNode
                )
            );
            this.ownersMenuLink.setAttribute(ATTRIBUTES.DATA_OPEN, 'false');
        }

        [].slice.call(this.modelMenuLinks).forEach((modelMenuLink) => {
            modelMenuLink.addEventListener(EVENTS.CLICK, this.modelMenuListener.bind(this));
        });

        if (document.body.classList.contains(CLASSES.SPECIALTY_PAGE)) {
            if (screen.gte(screen.SIZES.XLARGE)) {
                this.attachFocusIn();
                this.attachMouseDownUp();
            }
            customEventDispatcher.addEventListener(
                CUSTOM_EVENTS.SPECIALTY_PAGE_HASH_UPDATED,
                this.onSpecialtyPageHashUpdate.bind(this));

            this.topNav.dataset.analyticTrigger = 'header-open';
            screen.addResizeListener(this.onScreenResize.bind(this));
        }

        customEventDispatcher.addEventListener(
            CUSTOM_EVENTS.GLOBAL_NAV_DISABLE_TABBABLES,
            this.disableTabbables.bind(this)
        );

        customEventDispatcher.addEventListener(
            CUSTOM_EVENTS.GLOBAL_NAV_ENABLE_TABBABLES,
            this.enableTabbables.bind(this)
        );
    }

    attachFocusIn() {
        this.element.addEventListener(EVENTS.FOCUSIN, this.onFocusIn.bind(this));
    }

    detachFocusIn() {
        this.element.removeEventListener(EVENTS.FOCUSIN, this.onFocusIn.bind(this));
    }

    attachMouseDownUp() {
        this.element.addEventListener(EVENTS.MOUSEDOWN, this.onMouseDown.bind(this));
        this.element.addEventListener(EVENTS.MOUSEUP, this.onMouseUp.bind(this));
    }

    detachMouseDownUp() {
        this.element.removeEventListener(EVENTS.MOUSEDOWN, this.onMouseDown.bind(this));
        this.element.removeEventListener(EVENTS.MOUSEUP, this.onMouseUp.bind(this));
    }

    buildCarousel() {
        this.privateCarousel = new Carousel(this.carouselContainer);
    }

    init() {
        this.cacheDOM();
        this.tabbables = new Tabbables(this.element);
        this.setInitialVehicleIndex();
        this.buildCarousel();
        this.attachEvents();
    }

    get carousel() {
        return this.privateCarousel;
    }

    /**
     * Click handler for any of the main top level menu items
     */
    onMainMenuClick(e) {
        const currentItem = e.currentTarget;

        if ((screen.gte(screen.SIZES.LARGE)
            && this.specialtyPageState && this.specialtyPageState.enableSubmenus)
            || !this.specialtyPageState) {
            // Filter out all the menu links other than current link
            [].slice.call(this.mainMenuLinks).filter((mainMenuLink) =>
                (mainMenuLink.classList !== currentItem.classList))
            .forEach((mainMenuLink) => {
                const mainMenuLinkParent = mainMenuLink.parentNode;
                // Close the menu item
                mainMenuLinkParent.classList.remove(CLASSES.MENU_OPEN);
                mainMenuLinkParent.offsetParent.parentNode.classList.remove(CLASSES.NAV_CONTAINER_OPEN);
                mainMenuLink.setAttribute(ATTRIBUTES.DATA_OPEN, 'false');
            });

            const isMenuOpen = currentItem.getAttribute(ATTRIBUTES.DATA_OPEN) === 'true';

            if (isMenuOpen) {
                document.body.removeAttribute(ATTRIBUTES.DATA_SUBMENU_STATE);
                document.documentElement.removeAttribute(ATTRIBUTES.DATA_SUBMENU_STATE);
            } else {
                if (this.ownersMenuLink && this.ownersMenuLink.getAttribute(ATTRIBUTES.DATA_OPEN) === 'true') {
                    this.onOwnerMenuClose();
                }
                document.body.setAttribute(ATTRIBUTES.DATA_SUBMENU_STATE, CLASSES.BODY_OPEN);
                document.documentElement.setAttribute(ATTRIBUTES.DATA_SUBMENU_STATE, CLASSES.BODY_OPEN);

                // dispatch model selectors close event
                customEventDispatcher.dispatchEvent(
                    customEventDispatcher.createCustomEvent(CUSTOM_EVENTS.GLOBAL_NAV_MENU_OPENED)
                );
            }

            if (currentItem.getAttribute(ATTRIBUTES.DATA_MENU_ITEM) === 'vehicles' && !isMenuOpen) {
                // do our pagination
                setTimeout(() => {
                    this.carousel.updateView(this.currentIndex);
                }, 10);
            } else {
                if (this.modelMenuLinks.length > 0) {
                    this.isModelMenuLinkActive();
                }
                this.carousel.isActive = false;
            }

            document.body.setAttribute(ATTRIBUTES.DATA_MENU_CLASS,
                    currentItem.getAttribute(ATTRIBUTES.DATA_MENU_ITEM));
            currentItem.setAttribute(ATTRIBUTES.DATA_OPEN, !isMenuOpen);
        }
    }

    /**
     * Handler for clicks outside main menu
     */
    onMainMenuClose(elem) {
        const menuToClose = elem ? elem.dataset.menuItem : '';
        const currentlyOpenedMenu = document.body.getAttribute(ATTRIBUTES.DATA_MENU_CLASS);

        if (menuToClose === currentlyOpenedMenu) {
            document.body.removeAttribute(ATTRIBUTES.DATA_SUBMENU_STATE);
            document.documentElement.removeAttribute(ATTRIBUTES.DATA_SUBMENU_STATE);
            document.body.removeAttribute(ATTRIBUTES.DATA_MENU_CLASS);
            [].slice.call(this.mainMenuLinks).forEach((mainMenuLink) => {
                mainMenuLink.setAttribute(ATTRIBUTES.DATA_OPEN, 'false');
            });

            if (this.modelMenuLinks.length > 0) {
                this.isModelMenuLinkActive();
            }
        }
    }

    /**
     * Click handler for the top level owners menu item
     */
    onOwnerMenuClick(e) {
        const currentItem = e.currentTarget;
        const isMenuOpen = currentItem.getAttribute(ATTRIBUTES.DATA_OPEN) === 'true';

        let menuToClose = null;
        [].slice.call(this.mainMenuLinks).forEach((mainMenuLink) => {
            if (mainMenuLink.getAttribute(ATTRIBUTES.DATA_MENU_ITEM) ===
                    document.body.getAttribute(ATTRIBUTES.DATA_MENU_CLASS)) {
                menuToClose = mainMenuLink;
            }
        });

        currentItem.setAttribute(ATTRIBUTES.DATA_OPEN, !isMenuOpen);
        if (!isMenuOpen) {
            if (menuToClose) {
                this.onMainMenuClose(menuToClose);
            }
            currentItem.parentNode.classList.add(CLASSES.OWNERS_MENU_OPEN);
        } else {
            currentItem.parentNode.classList.remove(CLASSES.OWNERS_MENU_OPEN);
        }
    }

    /**
     * Handler for clicks outside owners menu
     */
    onOwnerMenuClose() {
        this.ownersMenuLink.parentNode.classList.remove(CLASSES.OWNERS_MENU_OPEN);
        this.ownersMenuLink.setAttribute(ATTRIBUTES.DATA_OPEN, 'false');
    }

    /**
     * Click handler for the vehicle sub nav items
     */
    vehicleMenuListener(event) {
        const currentItem = event.target;

        if (currentItem && currentItem.matches(`.${CLASSES.VEHICLES_SUB_MENU_ITEMS}`)) {
            const menu = currentItem.parentNode.querySelector(`.${CLASSES.VEHICLES_SUB_MENU_CONTAINER}`);
            LargeNav.removeActiveClass();
            currentItem.parentNode.classList.add(CLASSES.VEHICLES_SUB_MENU_ITEM_ACTIVE);
            menu.classList.remove(CLASSES.VEHICLES_SUB_MENU_CONTAINER_HIDDEN);
            currentItem.parentNode.parentNode.classList.add(CLASSES.VEHICLES_SUB_MENU_ACTIVE);
            // get current index for our carousel
            this.setVehicleIndexOf(currentItem);
            this.carousel.updateView(this.currentIndex);
        }
    }

    /**
     * Click handler for the model dropdown items
     */
    modelMenuListener(event) {
        const currentItem = event.target;

        if (currentItem && currentItem.matches(`.${CLASSES.VEHICLES_SUB_MENU_MODEL_LINKS}`)) {
            // Filter out all the model links other than current link
            [].slice.call(this.modelMenuLinks).filter((modelMenuLink) =>
                (modelMenuLink.classList !== currentItem.classList))
             .forEach((modelMenuLink) => {
                 this.closeModelMenu(modelMenuLink);
             });

            const isModelDropdownOpen = currentItem.getAttribute(ATTRIBUTES.DATA_OPEN) === 'true';

            currentItem.setAttribute(ATTRIBUTES.DATA_OPEN, !isModelDropdownOpen);

            // toggle model dropdown
            if (!isModelDropdownOpen) {
                document.body.addEventListener(EVENTS.CLICK, (e) => {
                    if (!e.target.matches(`.${CLASSES.VEHICLES_SUB_MENU_MODEL_LINKS}`)) {
                        this.closeModelMenu(currentItem);
                    }
                });
            }
        }
    }

    /**
     * Check for an active modelMenuLink
     */
    isModelMenuLinkActive() {
        [].slice.call(this.modelMenuLinks).filter((modelMenuLink) =>
            (modelMenuLink.getAttribute(ATTRIBUTES.DATA_OPEN) === 'true'))
        .forEach((modelMenuLink) => {
            this.closeModelMenu(modelMenuLink);
        });
    }

    /**
     * Open model menu dropdown
     */
    openModelMenu(item) {
        item.setAttribute(ATTRIBUTES.DATA_OPEN, 'true');
    }

    /**
     * Close model menu dropdown
     */
    closeModelMenu(item) {
        item.setAttribute(ATTRIBUTES.DATA_OPEN, 'false');
    }

    /**
     * Gets the index of the initial active carousel and sets the current index
     * to its index from the collection of carousels and sets a default active
     * carousel item if one has not been set
     */
    setInitialVehicleIndex() {
        const carousels = this.element.querySelectorAll(`[${ATTRIBUTES.VEHICLES_BODYSTYLE_SELECTED}]`);
        const activeCarousel = [].slice.call(carousels).findIndex(
            (carousel) => carousel.dataset.bodystyleSelected === 'true'
        );

        this.currentIndex = activeCarousel > 0 ? activeCarousel : 0;

        if (activeCarousel === -1) { // if no active submenu exists, make first submenu as active
            const activeSubMenuItem = document.querySelectorAll(`.${CLASSES.CAROUSEL_CONTAINER} .${CLASSES.VEHICLES_SUB_MENU_ITEM}`)[this.currentIndex];
            activeSubMenuItem.classList.add(CLASSES.VEHICLES_SUB_MENU_ITEM_ACTIVE);
        }
    }

    setVehicleIndexOf(item) {
        const grandparent = item.parentNode.parentNode;
        const parent = item.parentNode;
        const index = Array.prototype.indexOf.call(grandparent.children, parent);
        this.currentIndex = index;
    }

    static removeActiveClass() {
        const items = document.querySelectorAll(`.${CLASSES.VEHICLES_SUB_MENU} .${CLASSES.VEHICLES_SUB_MENU_ITEMS}`);
        [].slice.call(items).forEach((item) => {
            item.parentNode.classList.remove(CLASSES.VEHICLES_SUB_MENU_ITEM_ACTIVE);
            item.setAttribute(ATTRIBUTES.VEHICLES_BODYSTYLE_SELECTED, 'false');
        });

        const levels = document.querySelectorAll(`.${CLASSES.VEHICLES_SUB_MENU} .${CLASSES.VEHICLES_SUB_MENU_CONTAINER}`);
        [].slice.call(levels).forEach((level) => {
            level.classList.add(CLASSES.VEHICLES_SUB_MENU_CONTAINER_HIDDEN);
        });
    }

    triggerMenuClick() {
        [].slice.call(this.mainMenuLinks).forEach((mainMenuLink) => {
            if (!this.menuTriggered) {
                if (mainMenuLink.getAttribute(ATTRIBUTES.DATA_OPEN) === 'true') {
                    mainMenuLink.setAttribute(ATTRIBUTES.DATA_OPEN, 'false');
                    this.menuTriggered = true;
                }
            }
        });
    }

    /**
     * @method onScreenResize
     * @description handler for the onScreenResize event
     */
    onScreenResize() {
        if (screen.getState().large && this.currentChapterIndex) {
            this.setDockingState(this.currentChapterIndex !== 0);
        }
        if (document.body.classList.contains(CLASSES.SPECIALTY_PAGE)) {
            if (screen.gte(screen.SIZES.XLARGE)) {
                this.attachFocusIn();
                this.attachMouseDownUp();
            } else {
                this.detachFocusIn();
                this.detachMouseDownUp();
            }
        }
    }

    /**
     * @method onSpecialtyPageHashUpdate
     * @description handler for the SpecialtyPageHashUpdate
     * @param detail {Object} detail of the SpecialtyPageHashUpdate event
     */
    onSpecialtyPageHashUpdate({ detail }) {
        this.currentChapterIndex = detail.nextChapterData.index;

        if (screen.getState().large) {
            this.setDockingState(this.currentChapterIndex !== 0);
        } else if (screen.gte(screen.SIZES.XLARGE)) {
            if (!this.element.classList.contains(CLASSES.HEADER_DOCKING)) {
                this.setAriaHidden(false);
                setTimeout(() => {
                    this.element.classList.add(CLASSES.HEADER_DOCKING);
                    this.setAriaHidden(true);
                    this.element.addEventListener(EVENTS.MOUSELEAVE, this.closeSubmenus.bind(this));
                    this.element.addEventListener(EVENTS.MOUSEENTER, this.enableSubmenusOnMouseEnter.bind(this));
                }, DELAY.DELAY_1500MS);
            }

            if (!this.dockOnMouseLeave) {
                this.dockOnMouseLeave = true;
                this.element.addEventListener(EVENTS.MOUSELEAVE, this.onMouseLeave.bind(this));
                this.element.addEventListener(EVENTS.MOUSEENTER, this.onMouseEnter.bind(this));
            }
        }
    }

    /**
     * @method onFocusIn
     * @description when focus in any of the global nav elements
     */
    onFocusIn() {
        if (!this.mouseClick && !this.element.classList.contains(CLASSES.LOCKED)) {
            this.setDockingState(false);
            this.setAriaHidden(true);
            if (this.specialtyPageState) {
                this.specialtyPageState.enableSubmenus = true;
            }
        }

        document.body.addEventListener(EVENTS.FOCUSIN, this.findHeaderFocusOut.bind(this));
    }

    /**
     * @method findHeaderFocusOut
     * @description find if focus out of the global nav
     */
    findHeaderFocusOut(e) {
        const targetClass = e.target.classList.value.trim().replace(/( )+/g, '.');
        console.log(`${targetClass}, ${e.target.classList}`);
        if (!this.element.querySelectorAll(`.${targetClass}`).length) {
            this.onFocusOut();
            document.body.removeEventListener(EVENTS.FOCUSIN, this.findHeaderFocusOut.bind(this));
        }
    }

    /**
     * @method onFocusOut
     * @description when focus out of the global nav
     */
    onFocusOut() {
        if (!this.mouseClick) {
            this.setDockingState(true);
            this.setAriaHidden(false);
        }
    }

    /**
     * @method onMouseLeave
     * @description when mouse leave the global nav
     */
    onMouseLeave() {
        this.element.click();
        this.setAriaHidden(true);
    }

    /**
     * @method onMouseEnter
     * @description when mouse enter the global nav
     */
    onMouseEnter() {
        this.setAriaHidden(false);
    }

    /**
     * @method onMouseDown
     * @description when mouse click down on any item of the global nav
     */
    onMouseDown() {
        this.mouseClick = true;
    }

    /**
     * @method onMouseUp
     * @description when mouse up the global nav
     */
    onMouseUp() {
        this.mouseClick = false;
    }

    /**
     * @method setDockingState
     * @description set docking state of the header
     * @param dockingState {Boolean} docking state of the header
     */
    setDockingState(dockingState) {
        this.element.classList[dockingState ? 'add' : 'remove'](CLASSES.HEADER_DOCKING);
        if (this.specialtyPageState) {
            this.specialtyPageState.dockingState = dockingState;
            if (!dockingState) {
                this.specialtyPageState.enableSubmenus = true;
            } else {
                this.closeSubmenus();
            }
        }
    }

    /**
     * @method enableSubmenusOnMouseEnter
     * @description enable Submenus to be open
     */
    enableSubmenusOnMouseEnter() {
        if (this.specialtyPageState) {
            this.specialtyPageState.enableSubmenus = true;
        }
    }

    /**
     * @method closeSubmenus
     * @description disable menu toggle callback on header hide transition
     */
    closeSubmenus() {
        if (this.specialtyPageState && this.specialtyPageState.dockingState) {
            document.body.removeAttribute(ATTRIBUTES.DATA_SUBMENU_STATE);
            document.documentElement.removeAttribute(ATTRIBUTES.DATA_SUBMENU_STATE);
            document.body.removeAttribute(ATTRIBUTES.DATA_MENU_CLASS);
            [].slice.call(this.mainMenuLinks).forEach((mainMenuLink) => {
                mainMenuLink.setAttribute(ATTRIBUTES.DATA_OPEN, 'false');
            });
            this.specialtyPageState.enableSubmenus = false;
            this.onOwnerMenuClose();
        }
    }

    /**
     * @method setAriaHidden
     * @description set data aria hidden of the header
     * @param ariaHidden {Boolean} aria hidden
     */
    setAriaHidden(ariaHidden) {
        this.element.setAttribute(ATTRIBUTES.ARIA_HIDDEN, ariaHidden);
    }

    /**
     * @method disableTabbables
     * @description disable tabs on global nav
     */
    disableTabbables() {
        this.tabbables.saveTabIndices();
        this.tabbables.disableTabbables();
    }

    /**
     * @method enableTabbables
     * @description enable tabs on global nav
     */
    enableTabbables() {
        this.tabbables.enableTabbables();
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Util dependencies
import { htmlNode } from 'utils';

/**
 * @description Template for Form Error Message
 * Uses ES6 template literal to composite the markup for a Form Error Message
 */
export default ({ message = '' } = {}) => htmlNode`
    <div class="stay-connected__message success" data-analytics-container="{'blade':'newsletter-confirmed'}">
        <p class="stay-connected__subscribed-message"><span>${message}</span>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="12" fill="#37AC45"/>
        <path d="M9.817 17.048L6 12.308L7.29 11.272L9.896 14.512L16.776 6.95996L18 8.07396L9.817 17.048Z" fill="#FFFFFF"/>
        </svg>
        </p>
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * Sticky Class
 * @description Toggles 'is-pinned' class to element when it is pinned.
 */
export default class Sticky {
    constructor(element) {
        (new IntersectionObserver(
            ([e]) => e.target.classList.toggle('is-pinned', e.intersectionRatio < 1),
            { threshold: [1] }
        )).observe(element);
    }
}

// do not delete 9fbef606107a605d69c0edbcd8029e5d

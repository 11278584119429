/**
 * @const TYPES
 * @description Node Name types that can be interfaced with
 * @type {{PICTURE: string, IMAGE: string, SOURCE: string}}
 */
const TYPES = {
    PICTURE: 'PICTURE',
    IMAGE: 'IMG',
    SOURCE: 'SOURCE'
};

/**
 * @class DeferLoader
 * @description Module for deferred loading a picture or image element and its sources
 *
 * Example Usage:
 *
 * <picture>
 *     <source media="(max-width: 767px)" srcset="empty.gif" data-deferred-src="{src}" />
 *     <img src="empty.gif" alt="ImageAlt" data-deferred-src="{src}" />
 * </picture>
 *
 * <img src="empty.gif" alt="ImageAlt" data-deferred-src="{src}" />
 */
export default class DeferLoader {
    /**
     * @constructor
     * @param element {Node} The DOM element to defer load
     */
    constructor(element) {
        this.element = element;
        this.assets = null;

        this.init();
    }

    /**
     * @method init
     * @description init the DeferLoader Module
     */
    init() {
        this.setAssets();
        this.loadAssets();
    }

    /**
     * @method setAssets
     * @description Parses and sets image and source elements from the defer loader element
     */
    setAssets() {
        if (this.element.nodeName === TYPES.PICTURE) {
            const imageElm = this.element.querySelectorAll('img');
            const sourceElms = this.element.querySelectorAll('source');
            this.assets = [...imageElm].concat([...sourceElms]);
        } else if (this.element.nodeName === TYPES.IMAGE) {
            this.assets = [this.element];
        }
    }

    /**
     * @method loadAssets
     * @description Iterates each asset element to load its asset
     */
    loadAssets() {
        this.assets.forEach(this.loadAsset);
    }

    /**
     * @method loadAsset
     * @description Sets the source of the assets to load and applies a callback
     * to set its styling when fully loaded
     * @param asset {Node} Element to load the asset for
     * @return {DeferLoader} Returns itself for chaining methods
     */
    loadAsset(asset) {
        const { deferredSrc } = asset.dataset;
        const sourceType = asset.nodeName === TYPES.SOURCE ? 'srcset' : 'src';

        if (!deferredSrc) {
            return this;
        }

        asset[sourceType] = deferredSrc;

        return this;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

const { languageToggleLink, currentPageLink } = window.mbVans.pageData;

const CLASSES = {
    ACTIVELANG: "active-lang"
}

export default class Header {
    constructor() {
        this.elem = document.querySelector('.header');
        if (!this.elem) {
            return null;
        }
        this.activeMenuTimeOut = ''; // setTimeout variable for removing active and active-menu classes
        this.setHeight = this.setHeight.bind(this);
        this.init();
    }

    /**
     * @method setHeight
     * @description sets the height of nav menus based container 
     * on which sub nav is opened
     * @param {HTMLElement} item
     */
    setHeight(item) {
        if (window.innerWidth < 576) return;
        if (this.elem.classList.contains('show-dropdown')) {
            if (item.dataset.navIndex === '0') {
                this.navMenusElem.style.height = '45rem';
            } else {
                this.navMenusElem.style.height = '33.75rem';
            }
        } else {
            this.navMenusElem.style.height = '0';
        }
    }

    /**
     * @method onContactDealerClick
     * @description add or remove form class on click of contact a dealer button
     */
    onContactDealerClick() {
        this.listItems?.forEach(el => el.classList.remove('active'));
        if (this.elem.classList.contains('show-nav-list')) {
            this.elem.classList.remove('show-nav-list');
        }
        this.dataNavIdMenu.forEach(navItem => navItem.classList.remove('active-menu'));
        this.elem.classList.add('show-dealer-form');
        this.hamburgerMenu?.classList.remove('open');
    }

    /**
     * @method onItemClick
     * @description show or hide nav menu on click of nav item
     * @param {HTMLElement} item
     */
    onItemClick(item, e) {
        e.preventDefault();
        const listItem = item?.closest('.header__nav-item');
        const dynNavMenu = document.querySelector(`[data-nav-id="${item.id}--content"]`);
        if (!item || !item.id || !listItem) return null;
        if(listItem.classList.contains('active')) {
            // time out for removing the active and active-menu classes for the transition
            this.activeMenuTimeOut = setTimeout(() => {
                dynNavMenu.classList.remove('active-menu');
                listItem.classList.remove('active');
            }, 300);
            dynNavMenu.setAttribute('aria-expanded', false);
            this.elem.classList.remove('show-dropdown');
        } else {
            this.listItems?.forEach(el => el?.classList.remove('active'));
            listItem.classList.add('active');
            this.dataNavIdMenu.forEach(navItem => {
                navItem.classList.remove('active-menu');
                navItem.setAttribute('aria-expanded', false);
            });
            // remove the time out for active and active-menu classes
            clearTimeout(this.activeMenuTimeOut);
            dynNavMenu.classList.add('active-menu');
            dynNavMenu.setAttribute('aria-expanded', true);
            dynNavMenu.querySelector("a").focus();
            if(window.innerWidth < 576) {
                this.elem.classList.add('show-dropdown');
                listItem.classList.remove('active');
            } else {
                this.elem.classList.add('show-dropdown');
            }
        }
        this.setHeight(item);
    }
    
    /**
     * @method onBlurDropDown
     * @description on blur of dropdown menu
     */
    onBlur(e) {
        if (this.elem.classList.contains('show-dropdown')) {
            const isClickInside = [...this.navMenus].some(menu => menu.contains(e.target)) ||
                [...this.listItems].some(listItem => listItem.contains(e.target));
          
            if (!isClickInside) {
                this.elem.classList.remove('show-dropdown');
                this.listItems?.forEach(el => el?.classList.remove('active'));
                this.dataNavIdMenu.forEach(navItem => navItem.classList.remove('active-menu'));
                this.navMenusElem.style.height = '0';
            }
        }
    }

    /**
     * @method onHamburgerClick
     * @description add or remove classes to header based on hamburger state
     */
    onHamburgerClick(e) {
        if(e.type === 'click' || e.keyCode === 13) {
            if(this.hamburgerMenu?.classList.contains('open')) {
                this.hamburgerMenu?.setAttribute('aria-expanded', false);
                this.hamburgerMenu?.classList.remove('open');
                this.elem.classList.remove('show-nav-list', 'show-dropdown');
                this.listItems?.forEach(el => el?.classList.remove('active'));
                this.dataNavIdMenu.forEach(navItem => navItem.classList.remove('active-menu'));
            } else {
                this.hamburgerMenu?.setAttribute('aria-expanded', true);
                this.hamburgerMenu?.classList.add('open');
                this.elem.classList.add('show-nav-list');
            }
        }
    }

    /**
     * @method onLangChange
     * @description change the language on click of button element
     * @param {HTMLElement} btn
     */
    onLangChange(btn) {
        this.langBtns?.forEach(el => {
            if(el.dataset.type === btn.dataset.type) {
                el.classList.add(CLASSES.ACTIVELANG);
            } else {
                el?.classList.remove(CLASSES.ACTIVELANG)
            }
        });
    }

    /**
     * @method reset
     * @description remove all added classes to elements at certain widths
     */
    reset(e) {
        if (e.matches) {
            if(!this.elem || !this.hamburgerMenu) return null;
            this.elem.classList.remove('show-dealer-form', 'show-dropdown', 'show-nav-list');
            this.dataNavIdMenu.forEach(item => item.classList.remove('active-menu'));
            this.hamburgerMenu.classList.remove('open');
            this.listItems?.forEach(el => el?.classList.remove('active'));
        }
    }

    /**
     * @method cacheDOM
     * @description Caches the DOM elements of the application
     */
    cacheDOM() {
        this.hamburgerMenu = this.elem.querySelector('.header__nav-hamburger-menu');
        this.navMenusElem = this.elem.querySelector('.header__nav-menus');
        this.navMenus = this.elem.querySelectorAll('.header__nav-menu');
        this.dataNavIdMenu = this.elem?.querySelectorAll(`[data-nav-id]`);
        this.listItems = this.elem.querySelectorAll('.header__nav-item');
        this.linkItems = this.elem.querySelectorAll('.header__nav-item--link');
        this.linkItemSpan = this.elem.querySelectorAll('.header__nav-item--link span');
        this.langBtns = this.elem.querySelectorAll('.header__nav-lang-switcher .button');
        this.contactDealerBtn = this.elem.querySelector('.contact-a-dealer');
        this.formCloseBtn = this.elem.querySelector('.contact-a-dealer-form .icon');
        this.mqlDesktop = window.matchMedia("(min-width: 1024px)");
        this.mqlTablet = window.matchMedia("(min-width: 481px) and (max-width: 1023px)");
        this.mqlMobile = window.matchMedia("(max-width: 480px)");
    }

    /**
     * @method attachEvents
     * @description Attaches event listeners to the view
     */
    attachEvents() {
        this.contactDealerBtn?.addEventListener('click', this.onContactDealerClick.bind(this), false);
        this.linkItems?.forEach(item => {
            item?.addEventListener('click', this.onItemClick.bind(this, item), false);
        });
        document.addEventListener('click', this.onBlur.bind(this), false);
        this.hamburgerMenu?.addEventListener('click', this.onHamburgerClick.bind(this), false);
        this.hamburgerMenu?.addEventListener('keydown', this.onHamburgerClick.bind(this), false);
        this.langBtns?.forEach(btn => {
            btn?.addEventListener('click', this.onLangChange.bind(this, btn), false);
        });
        this.formCloseBtn?.addEventListener('click', this.onFormClose.bind(this), false);
        [this.mqlDesktop, this.mqlTablet, this.mqlMobile].map(item => {
            item.addEventListener('change', this.reset.bind(this), false);
        });
        ['click', 'keydown'].map(ev => {
            this.elem.addEventListener(ev, (e) => {
                const keydownValidation = ev === 'keydown' ? e.keyCode === 13 : true;
                if (e.target.matches('.header__nav-back-btn') && keydownValidation) {
                    this.elem.classList.remove('show-dropdown');
                    this.dataNavIdMenu.forEach(item => item.classList.remove('active-menu'));
                }
            }, false);
        });
    }

    /**
     * @method updateLanguageToggleLink
     * @description Attaches event listeners to the view
     */
    updateLanguageToggleLink() {
        if(this.langBtns && languageToggleLink) {
            this.langBtns?.forEach(btn => {
                if(!btn.classList.contains(CLASSES.ACTIVELANG)) {
                    btn?.setAttribute('href', languageToggleLink);
                }else {
                    btn?.setAttribute('href', currentPageLink);
                }
            })
        }
    }


    /**
     * @method init
     */
    init() {
        this.cacheDOM();
        this.updateLanguageToggleLink();
        this.attachEvents();
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d
// Util dependencies
import { htmlNode } from 'utils';

/**
 * @description Template for Subscribe Modal
 * Uses ES6 template literal to composite the markup for a Form Error Message
 */
export default ({
    header = '',
    subhead = '',
    disclaimer1 = '',
    disclaimer2 = '',
    submit = '',
    } = {}) => htmlNode`
    <div class="stay-connected-modal">
        <div class="stay-connected-modal__form">
            <h2 class="stay-connected-modal__header" id="subscribe-modal-header">${header}</h2>
            <p class="stay-connected-modal__subhead">${subhead}</p>
            <div class="stay-connected-modal__personal-information"></div>
            <div class="stay-connected-modal__disclaimers">
                <div class="stay-connected-modal__disclaimer">${disclaimer1}</div>
                <div class="stay-connected-modal__disclaimer">${disclaimer2}</div>
            </div>
            <div class="stay-connected-modal__error-message"></div>
            <button type="submit" class="button button_primary button--wide stay-connected-modal__submit" data-analytics-trigger="cta-subscribe-modal-submit">${submit}</button>
        </div>
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

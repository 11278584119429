import { screen } from 'utils';

import NavDealerLocator from './NavDealerLocator';
import ShoppingTools from '../ShoppingTools';
import LargeNav from './LargeNav';
import SmallNav from './SmallNav';

export default class Header {
    constructor(element) {
        this.element = element;
        this.init();
    }

    init() {
        if (screen.getState().small) {
            this.smallNav = new SmallNav(this.element);
        } else {
            this.largeNav = new LargeNav(this.element);
        }
        this.navDealerLocator = new NavDealerLocator(this.element);
        this.shoppingTools = new ShoppingTools(this.element);
        this.attachEvents();
    }

    /**
     * @method attachEvents
     * @description Attaches event listeners and callbacks to the view
     */
    attachEvents() {
        screen.addResizeListener(() => {
            if (this.smallNav &&
                (screen.gte(screen.SIZES.LARGE)) &&
                !this.largeNav) {
                this.largeNav = new LargeNav(this.element);
            }

            if (this.largeNav && screen.getState().small && !this.smallNav) {
                this.smallNav = new SmallNav(this.element);
            }

            if (this.smallNav && screen.gte(screen.SIZES.LARGE)) {
                this.largeNav.menuTriggered = false;
                this.smallNav.triggerMenuClick();
            }

            if (this.smallNav && screen.getState().small) {
                this.smallNav.menuTriggered = false;
                if (this.largeNav) {
                    this.largeNav.triggerMenuClick();
                }
            }

            if (screen.gte(screen.SIZES.XLARGE)) {
                this.largeNav.carousel.currentMaxItems = this.largeNav.carousel.maxItemsDesktop;
                this.largeNav.carousel.doPagination();
            }

            if (screen.getState().large) {
                this.largeNav.carousel.currentMaxItems = this.largeNav.carousel.maxItemsTablet;
                this.largeNav.carousel.doPagination();
            }
        });
        return this;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * SmallNav Module
 * Has methods related to composition of the header module
 */
import { CUSTOM_EVENTS, EVENTS } from 'Constants';
import { customEventDispatcher, screen } from 'utils';
import MultiLevelMenu from './MultiLevelMenu';

/**
 * @const ATTRIBUTES
 * @description Attribute references for the SmallNav Module
 * @type {{MENU_STATE: string}}
 */
const ATTRIBUTES = {
    MENU_STATE: 'data-menu-state'
};

const CLASSES = {
    HEADER_DOCKING: 'header--docking-state',
    SPECIALTY_PAGE: 'specialty-page'
};

export default class SmallNav {
    constructor(element) {
        this.body = null;
        this.clickBlocker = null;
        this.menuLinks = null;
        this.topNav = null;
        this.mlm = null;
        this.menuState = null;
        this.menuTriggered = null;
        this.element = element;
        this.cacheDOMElements.bind(this);
        this.attachEvents.bind(this);
        this.globalNavSearchListener = this.globalNavSearchListener.bind(this);
        this.onSpecialtyPageHashUpdate = this.onSpecialtyPageHashUpdate.bind(this);
        this.onScreenResize = this.onScreenResize.bind(this);
        this.init();
    }

    /**
     * @method cacheDOMElements
     * @description Caches the DOM elements of the module
     */
    cacheDOMElements() {
        this.body = window.document.querySelector('body');
        this.clickBlocker = this.element.querySelector('.header-click-blocker');
        this.menuToggle = this.element.querySelector('.menu-toggle__cta');
        this.topNav = this.element.querySelector('#top-nav');
        return this;
    }

    /**
     * @method attachEvents
     * @description Attaches event listener for
     * menuToggle, clickBlocker and GLOBAL_NAV_DEALER_SEARCH, and pageshow.
     */
    attachEvents() {
        window.addEventListener('pageshow', (event) => {
            if (event.persisted && this.menuStatus === 'open') {
                this.removeMenuStatus();
            }
        });
        this.menuToggle.addEventListener(EVENTS.CLICK, (e) => {
            e.preventDefault();
            if (!this.menuStatus) {
                this.menuStatus = 'open';
            } else {
                this.removeMenuStatus();
            }
            this.mlm.reInit();
        });
        this.clickBlocker.addEventListener(EVENTS.CLICK, (e) => {
            e.preventDefault();
            if (this.menuStatus) {
                this.removeMenuStatus();
            }
        });

        window.addEventListener(
                CUSTOM_EVENTS.GLOBAL_NAV_DEALER_SEARCH,
                this.globalNavSearchListener);

        if (document.body.classList.contains(CLASSES.SPECIALTY_PAGE)) {
            customEventDispatcher.addEventListener(
                CUSTOM_EVENTS.SPECIALTY_PAGE_HASH_UPDATED,
                this.onSpecialtyPageHashUpdate);

            screen.addResizeListener(this.onScreenResize);
        }

        return this;
    }

    /**
     * @method detachEvents
     * @description detach event GLOBAL_NAV_DEALER_SEARCH
     */
    detachEvents() {
        window.removeEventListener(
            CUSTOM_EVENTS.GLOBAL_NAV_DEALER_SEARCH,
            this.globalNavSearchListener);
    }

    /**
     * @method destroy
     * @description calls the deattchEvents method which
     * detaches event GLOBAL_NAV_DEALER_SEARCH
     */
    destroy() {
        this.detachEvents();
    }

    get menuStatus() {
        this.menuState = this.body.getAttribute(`${ATTRIBUTES.MENU_STATE}`);
        return this.menuState;
    }

    set menuStatus(state) {
        this.body.setAttribute(`${ATTRIBUTES.MENU_STATE}`, state);
    }

    /**
     * @method removeMenuStatus
     * @descriptionremoves removes the attribute
     * data-menu-state which results to global nav menu close.
     */
    removeMenuStatus() {
        this.body.removeAttribute(`${ATTRIBUTES.MENU_STATE}`);
    }

    triggerMenuClick() {
        if (!this.menuTriggered && this.menuStatus === 'open') {
            this.menuTriggered = true;
            this.menuToggle.click();
        }
    }

    /**
     * @method globalNavSearchListener
     * @description event listener for globalnavsearch
     * @param {event}
     */
    globalNavSearchListener() {
        this.removeMenuStatus();
    }

    /**
     * @method onSpecialtyPageHashUpdate
     * @description handler for the SpecialtyPageHashUpdate
     * @param detail {Object} detail of the SpecialtyPageHashUpdate event
     */
    onSpecialtyPageHashUpdate({ detail }) {
        this.currentChapterIndex = detail.nextChapterData.index;
        this.toggleHeaderDockingState(this.currentChapterIndex !== 0);
    }

    /**
     * @method onScreenResize
     * @description handler for the onScreenResize event
     */
    onScreenResize() {
        if (screen.getState().small && this.currentChapterIndex) {
            this.toggleHeaderDockingState(this.currentChapterIndex !== 0);
        }
    }

    /**
     * @method toggleHeaderDockingState
     * @description toggles docking state of the header
     * @param dockingState {Boolean} docking state of the header
     */
    toggleHeaderDockingState(dockingState) {
        this.element.classList[dockingState ? 'add' : 'remove'](CLASSES.HEADER_DOCKING);
    }

    init() {
        this.cacheDOMElements();
        this.attachEvents();

        this.mlm = new MultiLevelMenu(this.topNav);
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

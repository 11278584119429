/**
 * Back to top CTA module
 *
 */

import { EVENTS } from 'Constants';
import { debounce, scrollTo, isScrolledIntoView } from 'utils';

// CSS class definintions
const CLASSES = {
    CTA: 'back-to-top__cta',
    ENABLED: 'back-to-top--enabled',
    STICKY: 'back-to-top--sticky',
    GLOBAL_HEADER: 'global-header',
    HEADER: 'header',
    FOOTER: 'footer',
    SPECIALTY_PAGE: 'specialty-page',
    SPECIALTY_CHAPTER_DETAIL_PAGE: 'specialty-chapter-detail-page'
};

// DOM element types
const ELEMENTS = {
    BODY: 'body'
};

const viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
let scrollTop = 0;

export default class BackToTop {
    constructor(element) {
        this.element = element;
        this.backToTopBtn = null;
        this.footer = null;

        this.init();
    }

    init() {
        this.cacheDOM();
        if (!this.body.classList.contains(CLASSES.SPECIALTY_PAGE) &&
            !this.body.classList.contains(CLASSES.SPECIALTY_CHAPTER_DETAIL_PAGE)) {
            this.attachEventListeners();
        } else {
            this.destroy();
        }
    }

    cacheDOM() {
        this.backToTopBtn = this.element.querySelector(`.${CLASSES.CTA}`);
        this.body = document.querySelector(ELEMENTS.BODY);
        this.header = document.querySelector(`.${CLASSES.HEADER}, .${CLASSES.GLOBAL_HEADER}`);
        this.footer = document.querySelector(`.${CLASSES.FOOTER}`);
    }

    attachEventListeners() {
        window.addEventListener(EVENTS.SCROLL, debounce(this.onScroll.bind(this), 10));
        this.backToTopBtn.addEventListener(EVENTS.CLICK, this.onClick.bind(this));
    }

    /**
     * Scroll handler
     * Adds enabled and sticky classes based on scroll position
     */
    onScroll() {
        scrollTop = window.pageYOffset ||
            document.documentElement.scrollTop ||
            document.body.scrollTop ||
            0;

        this.element.classList[(scrollTop > 0.5 * viewportHeight) ? 'add' : 'remove'](CLASSES.ENABLED);
        this.element.classList[isScrolledIntoView(this.footer) ? 'add' : 'remove'](CLASSES.STICKY);
    }

    /**
     * Click handler
     * Scrolls the page to top on the click of back to top CTA and
     * sets the tab navigation to begin with header.
     */
    onClick(event) {
        event.preventDefault();
        scrollTo(0);
        this.header.setAttribute('tabindex', '0');
        this.header.focus();
        this.header.removeAttribute('tabindex');
        return this;
    }

    /**
     * @method destroy
     * @description removes Back To Top button from the DOM
     */
    destroy() {
        this.element.remove();
        this.element = null;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * @const defaultLocalization
 * @description Default localization labels for Dealer Locator App
 * @type {{label: String}}
 */
const defaultLocalization = {
    errorMessage: 'Invalid Postal Code or City, Province',
    searchInputLabel: 'Postal Code or City, Province'
};

// Deconstruct localization object
const {
    errorMessage,
    searchInputLabel
} = Object.assign(defaultLocalization, window.mbVans.ns('pageData').dealerNavLocalization);

/**
 * @const country
 * @description Default country for config
 * @type {{String}}
 */
const country = window.mbVans.ns('pageData').country || 'ca';

/**
 * @const country
 * @description Default language for config
 * @type {{String}}
 */
const language = window.mbVans.ns('pageData').language || 'en';

export default {
    country,
    language,
    errorMessage,
    searchInputLabel
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

/**
 * Template for Footer Button for docked mode
 * Uses ES6 template literal to composite the markup for the button
 */
import { htmlNode } from 'utils';

export default (localization) => htmlNode`
    <button class="docked-footer__button hidden" tabindex="-1"><span class="icon icon-arrow-up-square"></span>${localization.footer}</button>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

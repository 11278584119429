// Util dependencies
import { htmlNode } from 'utils';

/**
 * @description Template for Subscribe Modal
 * Uses ES6 template literal to composite the markup for a Form Error Message
 */
export default ({
    header = '',
    subhead = '',
    close = '',
    } = {}) => htmlNode`
    <div class="stay-connected-modal">
        <div class="stay-connected-modal__form">
            <h2 class="stay-connected-modal__header" id="subscribe-modal-header">${header}</h2>
            <p class="stay-connected-modal__subhead">${subhead}</p>            
            <button type="submit" class="button button_primary button--wide stay-connected-modal__submit" data-analytics-trigger="cta-subscribe-modal-close">${close}</button>
        </div>
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

import { CUSTOM_EVENTS, EVENTS } from 'Constants';
import { htmlNode } from 'utils';
import { LocationSearch } from 'partials/location-search';
import content from './config/content';

const CLASSES = {
    dealerTitle: '.top-nav__item.top-nav__item--dealer-title',
    dealerInput: 'dealer-locator-nav__input',
    dealerButton: 'dealer-locator-nav__btn',
    formInput: 'form__input-field',
    formDiv: '.dealer-locator-nav .form__input',
    dropDown: 'form__input-dropdown'
};

const ATTRIBUTES = {
    dealerLink: 'data-dealer-link',
    showDealerForm: 'data-show-dealer-form'
};

export default class NavDealerLocator {
    constructor(element) {
        this.element = element;
        this.language = content.language;
        this.country = content.country;
        this.searchInputLabel = content.searchInputLabel;
        this.errorMessage = content.errorMessage;
        this.isDealerApp = this.isDealerApp();
        this.model = null;
        this.theme = LocationSearch.THEMES.GLOBALNAV;
        this.dealerLinkElm = null;
        this.dealerNavElm = null;
        this.locationSearch = null;
        this.dealerInputContainer = null;
        this.dealerButtonElm = null;
        this.showDealerForm = 'false';
        this.expandDealerElm = this.expandDealerElm.bind(this);
        this.collapseDealerElm = this.collapseDealerElm.bind(this);
        this.cacheDOM();
        // dont't create search for legacy external link
        if (this.showDealerForm === 'true') {
            this.attachEvents();
            this.createLocationSearch();
        }
    }

    /**
     * @method cacheDOM
     * @description Caches DOM elements
     */
    cacheDOM() {
        this.dealerNavElm = this.element.querySelector(`${CLASSES.dealerTitle}`);
        this.dealerInputContainer = this.element.querySelector(`.${CLASSES.dealerInput}`);
        this.dealerButtonElm = this.element.querySelector(`.${CLASSES.dealerButton}`);
        this.dealerLinkElm = this.element.querySelector(`[${ATTRIBUTES.dealerLink}]`);
        this.showDealerForm = this.element.querySelector(`[${ATTRIBUTES.showDealerForm}]`).dataset.showDealerForm;
    }

    /**
     * @method createLocationSearch
     * @description Creates an instance of a LocationSearch and
     * renders it to the locationSearchElem element
     */
    createLocationSearch() {
        this.locationSearch = new LocationSearch({
            searchLength: 3,
            searchInputLabel: this.searchInputLabel,
            model: this.model,
            country: this.country,
            errorMessage: this.errorMessage,
            theme: this.theme,
            onSubmit: this.submitLocation.bind(this),
            onSubmitBtn: this.dealerButtonElm,
            disableReset: true
        });
        this.dealerInputContainer.appendChild(this.locationSearch.render());

        document.querySelector(`.${CLASSES.dealerInput} .${CLASSES.formInput}`)
        .placeholder = this.searchInputLabel;

        const dropdown = htmlNode`<p class="${CLASSES.dropDown}">${this.searchInputLabel}</p>`({ getNode: true });
        document.querySelector(`${CLASSES.formDiv}`).appendChild(dropdown);
    }

    /**
     * @method isDealerApp
     * @description checks if current app is dealer-locator or not
     */
    isDealerApp() {
        return (window.mbVans && window.mbVans.apps && (window.mbVans.apps.indexOf('dealer-locator') !== -1))
            || !!window.mbVans.ns('pageData', 'dealerLocator', 'config').isFullPageLayout;
    }

    /**
     * @method submitLocation
     * @description When a location is submitted, calls the `onSubmitCallback`
     * method with the submitted locations postal code
     * @param loc {Object} Location object from LocationSearch
     */
    submitLocation(loc) {
        if (this.isDealerApp) {
            let searchLocation = {};
            if (loc.type === 'place' && loc.id) {
                searchLocation = {
                    searchLocation: loc.id,
                    searchByType: 'place'
                };
            } else if (loc.type === 'zip' && loc.value) {
                searchLocation = {
                    searchLocation: loc.value,
                    searchByType: 'zip'
                };
            }
            this.emitSearch(searchLocation);
        } else {
            let locationValue = '';
            if (loc.type === 'zip' && loc.value) {
                locationValue = loc.value;
            } else if (loc.type === 'place' && loc.id) {
                locationValue = loc.id;
            }
            const url = this.constructUrl(locationValue, loc.type);
            window.location.href = url;
        }
    }

    /**
     * @method emitSearch
     * @description Sets the selected search location
     * @param location {Object} Location object from LocationSearch
     */
    emitSearch(searchLocation) {
        const globalNavSearchEvent = new window.CustomEvent(
            CUSTOM_EVENTS.GLOBAL_NAV_DEALER_SEARCH,
            { detail: searchLocation });
        window.dispatchEvent(globalNavSearchEvent);
    }

    /**
     * @method constructUrl
     * @description construct url to redirect to dealer-locator-app
     * @param searchLocation {String}
     * @param searchByType {String}
     */
    constructUrl(searchLocation, searchByType) {
        if (this.dealerLinkElm) {
            const dealerLink = this.dealerLinkElm.dataset.dealerLink;
            const searchStr = `searchLocation-${searchLocation}/searchByType-${searchByType}`;
            return `${dealerLink}/${searchStr}`;
        }
        console.error('no dealer link element');
        return '';
    }

    /**
     * @method expandDealerElm
     * @description Display the dealer top-nav search element
     */
    expandDealerElm(event) {
        event.preventDefault();
        this.dealerNavElm.setAttribute('data-expanded', 'true');
    }

    /**
     * @method collapseDealerElm
     * @description Hide the dealer top-nav search element
     */
    collapseDealerElm(event) {
        if (this.dealerNavElm.dataset.expanded === 'true') {
            const isChild = event.target.parentElement.className.indexOf('dealer') !== -1 ||
            event.target.parentElement.className.indexOf('form') !== -1;

            // if clicked anywhere outside the dealer-nav area, collapse it
            if (!isChild) {
                this.dealerNavElm.setAttribute('data-expanded', 'false');
            }
        }
    }

    /**
     * @method attachEvents
     * @description Attaches event listeners and callbacks to the view
     */
    attachEvents() {
        this.dealerNavElm.addEventListener(EVENTS.CLICK, this.expandDealerElm);
        document.addEventListener(EVENTS.CLICK, this.collapseDealerElm);
        return this;
    }

    /**
     * @method detachEvents
     * @description Detaches event listeners and callbacks from the view
     */
    detachEvents() {
        this.dealerNavElm.removeEventListener(EVENTS.CLICK, this.expandDealerElm);
        document.removeEventListener(EVENTS.CLICK, this.collapseDealerElm);
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

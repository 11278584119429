import { EVENTS } from 'Constants';
import { screen, debounce } from 'utils';

/**
 * CLASSES{}
 * stores class names for use in the DOM
 */

const CLASSES = {
    CAROUSEL_CONTAINER: 'top-nav__sub-nav--carousel',
    CAROUSEL_CONTAINER_ACTIVE: 'top-nav__sub-nav--carousel--active',
    CAROUSEL_ACTIVE_RIGHT: 'top-nav__sub-nav--carousel--active-right',
    CAROUSEL_ACTIVE_LEFT: 'top-nav__sub-nav--carousel--active-left',
    CAROUSEL_ITEM: 'top-nav__item',
    CAROUSEL_VEHICLE_LINK: 'top-nav__link--vehicle-link',
    CAROUSEL_LIST: 'top-nav__vehicle-list',
    CAROUSEL_ITEM_HIDDEN: 'top-nav__item--hidden',
    CAROUSEL_RIGHT_ARROW: 'vehicle__controls--right-arrow',
    CAROUSEL_LEFT_ARROW: 'vehicle__controls--left-arrow',
    CAROUSEL_CONTROLS_HIDDEN: 'vehicle__controls--hidden',
};

/**
 * PROPS{}
 * stores common properties for the carousel
 */

const PROPS = {
    MAX_ITEMS_DESKTOP: 5,
    MAX_ITEMS_TABLET: 4,
    ITEM_GUTTER: 15,
    SHOW: 'show',
    HIDE: 'hide',
    UNITS: 'px',
    RIGHT_ARROW: 'ArrowRight',
    LEFT_ARROW: 'ArrowLeft',
    TAB: 'Tab'
};

export default class Carousel {
    /**
     * constructor()
     * instantiates the Carousel class
     * @param {Object} element
     */

    constructor(element) {
        this.element = element;
        this.rightArrowVisible = null;
        this.leftArrowVisible = null;
        this.privateItems = null;
        this.currentItems = null;
        this.vehicleList = null;
        this.currentIndex = 0;
        this.isActive = false;
        this.isSwiped = false;
        this.distance = 0;
        this.interval = 1000;
        this.timeout = null;
        this.currentScreenState = screen.getState();
        this.privateMaxItemsDesktop = PROPS.MAX_ITEMS_DESKTOP;
        this.privateMaxItemsTablet = PROPS.MAX_ITEMS_TABLET;
        this.privateCurrentMaxItems = (screen.gte(screen.SIZES.XLARGE))
            ? this.maxItemsDesktop : this.maxItemsTablet;
        this.controls = {};
        this.init();
    }

    /**
     * cacheDom()
     * caches DOM elements for later use
     */

    cacheDOM() {
        this.controls.leftArrow = document.querySelector(`.${CLASSES.CAROUSEL_LEFT_ARROW}`);
        this.controls.rightArrow = document.querySelector(`.${CLASSES.CAROUSEL_RIGHT_ARROW}`);
        this.currentItems = this.items[this.currentIndex].querySelectorAll(`.${CLASSES.CAROUSEL_ITEM}`);
        this.vehicleList = document.querySelectorAll(`.${CLASSES.CAROUSEL_LIST}`);
        this.carouselContainer = document.querySelectorAll(`.${CLASSES.CAROUSEL_CONTAINER}`);
    }

    /**
     * attachEvents()
     * handles event listeners for our click events
     */

    attachEvents() {
        screen.addResizeListener((state) => {
            this.onScreenResize(state);
        });

        this.controls.rightArrow.addEventListener(EVENTS.CLICK, (e) => {
            this.paginationListener(e);
        });

        this.controls.leftArrow.addEventListener(EVENTS.CLICK, (e) => {
            this.paginationListener(e);
        });

        this.element.addEventListener(EVENTS.KEYDOWN, (e) => {
            this.keyboardListener(e);
        });

        Array.prototype.forEach.call(this.carouselContainer, ((item) => {
            item.addEventListener(EVENTS.WHEEL, (event) => {
                this.wheelListener(event);
            });
            item.addEventListener(EVENTS.TOUCHEND,
                debounce(this.touchListener.bind(this), 16));
        }));
    }

    /**
     * init()
     * calls initial functions
     */

    init() {
        this.cacheDOM();
        this.attachEvents();
        this.resetView();
    }

    /*
    Getters/Setters
    */

    /**
     * items()
     * gets all <ul> objects within the vehicle subnav
     * @returns {Array} array of <uls>
     */

    get items() {
        this.$privateItems = document.querySelectorAll(`.${CLASSES.CAROUSEL_LIST}`);
        return this.$privateItems;
    }

    /**
     * maxItemsDesktop()
     * gets the max number of cards for desktop
     * @returns {Number}
     */

    get maxItemsDesktop() {
        return this.privateMaxItemsDesktop;
    }

    /**
     * maxItemsTablet()
     * gets the max number of cards for tablet
     * @returns {Number}
     */

    get maxItemsTablet() {
        return this.privateMaxItemsTablet;
    }

    /**
     * currentMaxItems()
     * gets the the current number of max items based on desktop/tablet
     * @returns {Number}
     */

    get currentMaxItems() {
        return this.privateCurrentMaxItems;
    }

    /**
     * currentMaxItems()
     * sets the max number of cards for desktop/tablet
     * @param {Number}
     */

    set currentMaxItems(value) {
        this.privateCurrentMaxItems = value;
    }

    /*
    Core Functionality
    */

    /**
     * doPagination()
     * toggles our pagination controls based on number of cards for current tab
     */

    doPagination() {
        if (!this.rightArrowVisible) {
            this.resetHiddenItems();
        }

        this.currentItems = this.items[this.currentIndex].querySelectorAll(`.${CLASSES.CAROUSEL_ITEM}`);

        if (this.currentItems.length > this.currentMaxItems) {
            this.toggleRightArrow(PROPS.SHOW);
            this.hideItems();
            this.carouselContainer[this.currentIndex].classList
            .add(CLASSES.CAROUSEL_CONTAINER_ACTIVE);
        } else {
            this.toggleRightArrow(PROPS.HIDE);
            this.carouselContainer[this.currentIndex].classList
            .remove(CLASSES.CAROUSEL_CONTAINER_ACTIVE);
        }
    }

    /**
     * toggleRightArrow()
     * toggles the display of the right arrow pagination control
     * @param {String} 'show' or 'hide'
     */

    toggleRightArrow(display) {
        if (display === PROPS.SHOW) {
            this.rightArrowVisible = true;
            this.controls.rightArrow.classList.remove(CLASSES.CAROUSEL_CONTROLS_HIDDEN);
        } else if (display === PROPS.HIDE) {
            this.rightArrowVisible = false;
            this.controls.rightArrow.classList.add(CLASSES.CAROUSEL_CONTROLS_HIDDEN);
        } else {
            this.rightArrowVisible = false;
            this.controls.rightArrow.classList.add(CLASSES.CAROUSEL_CONTROLS_HIDDEN);
        }
    }

    /**
     * toggleLeftArrow()
     * toggles the display of the left arrow pagination control
     * @param {String} 'show' or 'hide'
     */

    toggleLeftArrow(display) {
        if (display === PROPS.SHOW) {
            this.leftArrowVisible = true;
            this.controls.leftArrow.classList.remove(CLASSES.CAROUSEL_CONTROLS_HIDDEN);
        } else if (display === PROPS.HIDE) {
            this.leftArrowVisible = false;
            this.controls.leftArrow.classList.add(CLASSES.CAROUSEL_CONTROLS_HIDDEN);
        } else {
            this.leftArrowVisible = false;
            this.controls.leftArrow.classList.add(CLASSES.CAROUSEL_CONTROLS_HIDDEN);
        }
    }

    /**
     * hideItems()
     * hides all items that are not currently in view
     */

    hideItems() {
        this.hiddenIndex = 0;

        [].slice.call(this.currentItems).forEach((item, index) => {
            item.classList.remove(CLASSES.CAROUSEL_ITEM_HIDDEN);
            item.removeAttribute('style');
            if (index > this.currentMaxItems - 1) {
                item.classList.add(CLASSES.CAROUSEL_ITEM_HIDDEN);
                this.hiddenIndex += 1;

                if (this.rightArrowVisible) {
                    this.positionHiddenItems(item, this.hiddenIndex);
                }
            }
        });
    }

    /**
     * resetHiddenItems()
     * removes the hidden class from all offscreen items
     * TODO : determine why going from desktop > tablet > desktop breaks on Sedans & Wagons
     */

    resetHiddenItems() {
        [].slice.call(this.currentItems).forEach((item) => {
            item.classList.remove(CLASSES.CAROUSEL_ITEM_HIDDEN);
        });
    }

    /**
     * resetView()
     * hides all offscreen items and hides pagination controls
     */

    resetView() {
        if (this.leftArrowVisible) {
            this.paginationListener();
        }
        this.hideItems();
        this.toggleRightArrow(PROPS.HIDE);
        this.toggleLeftArrow(PROPS.HIDE);
    }

    /**
     * updateView() - called from LargeNav.js
     * updates our view anytime a sub nav body group is selected
     * @param {Number} index - the index of the subnav item we are navigating to
     */

    updateView(index) {
        this.resetView();
        this.isActive = true;
        this.currentIndex = index;
        this.doPagination();
    }

    /**
     * positionHiddenItems()
     * positions the offscreen cards
     * @param {Object} item - the element to position
     * @param {Number} index - the index of the offscreen item
     */

    positionHiddenItems(item, index) {
        setTimeout(() => {
            const offsetLeft = this.currentItems[this.currentMaxItems - 1] ?
                this.currentItems[this.currentMaxItems - 1].offsetLeft : 0;
            const offsetWidth = this.currentItems[this.currentMaxItems - 1] ?
                (this.currentItems[this.currentMaxItems - 1].offsetWidth * index) +
                (PROPS.ITEM_GUTTER * index) : 0;
            const xPos = offsetLeft + offsetWidth;
            const el = item;
            el.style.left = `${xPos}${PROPS.UNITS}`;
            // set our distance
            this.distance = offsetWidth;
        }, 500);
    }

    /**
     * onScreenResize()
     * callback for screen resize event
     * @param {Object} newState - a Screen state object
     */

    onScreenResize(newState) {
        // Only execute if a breakpoint has been crossed
        if (this.currentScreenState !== newState) {
            this.currentScreenState = newState;
            this.currentMaxItems = (screen.gte(screen.SIZES.XLARGE)) ?
                this.maxItemsDesktop : this.maxItemsTablet;
            this.init();
        }
    }

    /**
     * paginationListener()
     * callback for our click event for our pagination controls
     * @param {Event} event - a click event
     */

    paginationListener(event) {
        if (event) {
            event.preventDefault();
        }
        const isRightArrow = event ?
            event.currentTarget.classList.contains(CLASSES.CAROUSEL_RIGHT_ARROW) : false;
        const controlType = (isRightArrow === true) ? 'right' : 'left';
        const operator = (controlType === 'right') ? '-' : '';
        const activeClass = (controlType === 'right') ? CLASSES.CAROUSEL_ACTIVE_RIGHT : CLASSES.CAROUSEL_ACTIVE_LEFT;
        const currentDistance = this.distance;
        this.carouselContainer[this.currentIndex].classList.add(activeClass);
        this.hiddenIndex = 0;

        if (controlType === 'right') {
            // the right arrow was pressed, so hide it and show the left arrow
            this.toggleRightArrow(PROPS.HIDE);
            this.toggleLeftArrow(PROPS.SHOW);
        } else if (controlType === 'left') {
            // the left arrow was pressed, so hide it and show the right arrow
            this.distance = 0;  // resets distance to 0 so left arrow just resets margins
            this.toggleRightArrow(PROPS.SHOW);
            this.toggleLeftArrow(PROPS.HIDE);
        }

        // Adjusts our hidden items right or left
        [].slice.call(this.currentItems).forEach((item, index) => {
            const element = item;

            if (index > this.currentMaxItems - 1) {
                this.hiddenIndex += 1;
                element.style.marginLeft = `${operator}${this.distance}${PROPS.UNITS}`;
            }
        });

        // Adjusts our first <li> element right or left
        const totalDistance = this.distance * 2;
        this.currentItems[0].style.marginLeft = `${operator}${totalDistance}${PROPS.UNITS}`;

        // restore our distance so right arrow continues to work
        this.distance = currentDistance;
    }

    /**
     * keyboardListener()
     * callback for our left/right  and focus keyboard events for our pagination controls
     * @param {Event} event - a keyboard event
     */

    keyboardListener(event) {
        const keyName = event.key;

        if (keyName === PROPS.RIGHT_ARROW || keyName === PROPS.LEFT_ARROW) {
            this.triggerPagination(keyName);
        }

        if (!this.currentScreenState.small && keyName === PROPS.TAB
                && document.activeElement.classList.contains(CLASSES.CAROUSEL_VEHICLE_LINK)) {
            const activeElement = document.activeElement;
            const activeElementRect = activeElement.getBoundingClientRect();
            const activeElementContainerRect = this.carouselContainer[0].getBoundingClientRect();
            let nextElement;
            let nextElementRect;
            const parentElement = activeElement.parentElement;
            if (parentElement.nextSibling.nextSibling) {
                nextElement = parentElement.nextSibling.nextSibling;
                nextElementRect = nextElement.getBoundingClientRect();
            }

            /* the check for nextElementRect instead of activeElementRect is needed
             * to fix the whitespace issue on ie11 where tab behavior responds before right click
             * and with activeElementRect it adds white space to right on ie11.
             */

            if (nextElement !== undefined
                    && nextElement.classList.contains(CLASSES.CAROUSEL_ITEM)
                    && nextElementRect.right > activeElementContainerRect.right) {
                // i.e next element is hidden behind right arrow
                this.controls.rightArrow.click();
            } else if (activeElementRect.left < activeElementContainerRect.left) {
                // i.e focused element is hidden behind left arrow
                this.controls.leftArrow.click();
            }
        }
    }

    /**
     * wheelListener()
     * callback for our left and right horizontal scroll/swipe
     * @param {Event} event - a mouse wheel event
     */

    wheelListener(event) {
        event.preventDefault();
        if (event.wheelDeltaY === 0) {
            // we are swiping left/right
            const direction = (event.deltaX >= 0) ? PROPS.RIGHT_ARROW : PROPS.LEFT_ARROW;
            if (!this.isSwiped) {
                this.triggerPagination(direction);
                this.isSwiped = true;
                this.timeout = setTimeout(() => {
                    this.isSwiped = false;
                }, 150);
            }
        }
    }

    /**
     * Swipe handler
     * @param {Event} event - a touchend event
     */
    touchListener(event) {
        event.preventDefault();
        const pageX = event.pageX;
        const changedTouch = event.changedTouches[0];
        const newPageX = changedTouch.pageX;
        const diffX = newPageX - pageX;
        if (isNaN(diffX)) {
            return false;
        } else if (diffX > 0 && this.rightArrowVisible) {
            this.triggerPagination(PROPS.RIGHT_ARROW);
        } else if (diffX < 0 && this.leftArrowVisible) {
            this.triggerPagination(PROPS.LEFT_ARROW);
        }
        return false;
    }

    /**
     * triggerPagination()
     * triggers a click event for our pagination controls
     * @param {String} key - the key that was pressed
     */

    triggerPagination(key) {
        switch (key) {
        case PROPS.RIGHT_ARROW:
            if (this.isActive && this.rightArrowVisible) {
                this.controls.rightArrow.click();
            }
            break;

        case PROPS.LEFT_ARROW:
            if (this.isActive && this.leftArrowVisible) {
                this.controls.leftArrow.click();
            }
            break;
        default:
            break;
        }
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Constant dependencies
import { EVENTS } from 'Constants';

import { renderer } from 'utils';

// Partial dependencies
import { InputTextControl } from 'partials/input-control';
import { LoadingButtonControl } from 'partials/button-control';

import footerTemplates from 'partials/footer';

const CLASSES = {
    RENDERED_EMAIL: 'stay-connected__htl',
    FORM_INPUT: 'form__input',
    FORM_INPUT_FIELD: 'form__input-field',
    SUBSCRIBE_CTA: 'stay-connected__subscribe-cta',
    TRY_AGAIN_CTA: 'stay-connected__try-again-cta',
    ERROR_MESSAGE: 'stay-connected__message',
    BUTTON_VALUE_TEXT: 'button__value-text'
};

const { subscribeEmailSubscribed, subscribeEmailExists, subscribeEmailError } = window.mbVans.ns('pageData').footerLocalization || {};

const { country, brand } = window.mbVans.pageData;

/**
 * @class StyleGuideRegistrationForm
 * @description Creates a FooterSubscribeForm
 * @param {Object} element
 */
export default class FooterSubscribeForm {
    /**
     * @method constructor
     * @description Instantiates StyleGuideRegistrationForm
     * @param {Node} element
     */
    constructor(element) {
        this.element = element;
        this.validate = this.validate.bind(this);
        this.submit = this.submit.bind(this);
        this.init();
    }

    /**
     * @method init
     * @description Init method
     */
    init() {
        this.cacheDOM();
        this.createControls();
    }

    /**
     * @method cacheDOM
     * @description Caches DOM elements
     */
    cacheDOM() {
        this.renderedEmailElm = this.element.querySelector(`.${CLASSES.RENDERED_EMAIL} .${CLASSES.FORM_INPUT}`);
        this.inputFieldElm = this.element.querySelector(`.${CLASSES.FORM_INPUT_FIELD}`);
        this.validateButtonElm = this.element.querySelector(`.${CLASSES.SUBSCRIBE_CTA}`);
        this.buttonLabelElm = this.validateButtonElm.querySelector(`.${CLASSES.BUTTON_VALUE_TEXT}`);
    }

    /**
     * @method createInputs
     * @description Creates input for email U Subscribe button
     */
    createControls() {
        this.inputs = [
            new InputTextControl({
                renderedInput: this.renderedEmailElm,
                validation: [
                    {
                        type: InputTextControl.VALIDATION_TYPE.REQUIRED,
                        errorMessage: window.mbVans.ns('pageData').footerLocalization.subscribeEmailErrorEmpty
                    },
                    {
                        type: InputTextControl.VALIDATION_TYPE.MAX_LENGTH,
                        errorMessage: window.mbVans.ns('pageData').footerLocalization.subscribeEmailErrorLength
                    },
                    {
                        type: InputTextControl.VALIDATION_TYPE.EMAIL,
                        errorMessage: window.mbVans.ns('pageData').footerLocalization.subscribeEmailErrorInvalid
                    }
                ],
                restrictions: [InputTextControl.RESTRICTION_TYPE.EMAIL_SPECIAL_CHARS]
            })
        ];

        this.buttons = [
            new LoadingButtonControl({
                renderedControl: this.validateButtonElm,
                valueText: this.buttonLabelElm.innerHTML,
                loadingValueText: this.validateButtonElm.dataset.loadingValueText,
                onClick: this.validate
            })
        ];
        this.validateButtonElm.removeAttribute('data-loading-value-text');
    }

    /**
     * @method validate
     * @description Calls validation method for each input
     * @param {Event} e Click event
     * @param {Function} setButtonComplete Callback function for LoadingButtonControl
     */
    validate(e, setButtonComplete) {
        this.setButtonComplete = setButtonComplete;
        this.removeErrorMessage();
        let valid = true;
        this.inputs.forEach((input) => {
            if (!input.validate()) {
                valid = false;
            }
        });
        if (valid) {
            this.submit();
        } else {
            this.setButtonComplete();
            this.inputFieldElm.focus();
        }
    }

    /**
     * @method submit
     * @description Submits form
     */
    submit() {
        const eml = encodeURIComponent(this.inputs[0].getValue());
        // OneWb
        let subscribeEndpoint =
        `/content/public-api/forms/preference/submit?api=subscribe&country=${country}&brand=${brand}&emailId=${eml}`;

        let options = {
            headers: {
                'Content-Type': 'application/json',
                'Referrer-Policy': 'origin',
                Referer: window.location.href
            },
            method: 'POST'
        };

        // Classic
        if (brand === 'FL') {
            subscribeEndpoint = `/freightliner/subscription/{email}/{options}/{inout}?email=${eml}&options=SPN%2CFSO&inout=I`;

            options = {
                headers: {
                    'Content-Type': 'application/json',
                    'Referrer-Policy': 'origin',
                    Referer: window.location.href,
                }
            };
        }

        return fetch(`${subscribeEndpoint}`, options)
        .then((response) => response.json())
        .then((response) => {
            if (!response) {
                throw Error('Lead Submission Not Successful');
            } else {
                this.onResponse(response);
            }
        })
        .catch((error) => {
            console.warn(error);
            this.onResponse();
        });
    }

    /**
     * @method onResponse
     * @description Handles submission response
     * @param {Object} response Data returned from AJAX request
     */
    onResponse(response) {
        this.setButtonComplete();
        if (response &&
            response.status.code === 200 &&
            (!response.result ||
            response.result.httpMessage === 'Created')) {
            this.showSuccessResponse();
        } else if (response &&
            response.status.code === 200 &&
            response.result.httpMessage === 'OK') {
            this.showExistingResponse();
        } else {
            this.showErrorResponse();
        }
        return false;
    }

    /**
     * @method showSuccessResponse
     * @description Shows response for new user
     */
    showSuccessResponse() {
        const element = footerTemplates.SubscribeSuccessTemplate({
            message: subscribeEmailSubscribed
        })({ getNode: true });
        renderer.insert(element, this.element);
    }

    /**
     * @method showExistingResponse
     * @description Shows response for existing user
     */
    showExistingResponse() {
        const element = footerTemplates.SubscribeExistsTemplate({
            message: subscribeEmailExists
        })({ getNode: true });
        renderer.insert(element, this.element);
    }

    /**
     * @method showErrorResponse
     * @description Shows response for error with AJAX request
     */
    showErrorResponse() {
        const elem = footerTemplates.SubscribeErrorTemplate({
            message: subscribeEmailError
        })({ getNode: true });
        renderer.append(elem, this.element);
        this.tryAgainButtonElm = this.element.querySelector(`.${CLASSES.TRY_AGAIN_CTA}`);
        if (this.tryAgainButtonElm) {
            this.tryAgainButtonElm.addEventListener(EVENTS.CLICK, this.submit);
        }
    }

    /**
     * @method removeErrorResponse
     * @description Removes a previous error response
     */
    removeErrorMessage() {
        const errorMessage = this.element.querySelector(`.${CLASSES.ERROR_MESSAGE}`);
        if (errorMessage) {
            errorMessage.remove();
        }
        this.tryAgainButtonElm = undefined;
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

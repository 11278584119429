import getLevelDepth from '@mbusa/now-ui-utils.get-level-depth';
import { EVENTS } from 'Constants';

const CLASSES = {
    SUB_NAV_LEVEL: 'top-nav__level',
    SUB_NAV_LEVEL_OPEN: 'top-nav__level--open',
    SUB_NAV_LEVEL_ACTIVE: 'top-nav__level--active',
    NAV_ITEM: 'top-nav__item',
    SUB_NAV_BACK_BTN: 'top-nav__link_back-btn',
    SUB_NAV_LEVEL_LINK: 'top-nav__link_parent'
};

const ATTRIBUTES = {
    DATA_LEVEL: 'data-level'
};

/**
 * Class representing the multi level mobile menu
 *
 * Following are the expected class names for different menu artifacts
 *     level/sub-level : .top-nav__level
 *     menu item: .top-nav__item
 *     links to sub-level: .top-nav__link_parent
 *     back button: .top-nav__link_back-tn
 *
 */
export default class MultiLevelMenu {
    /**
     * Create the MultiLevelMenu.
     * Also initializes some default values
     * @param {Node} element - Element of the multi level menu
     */
    constructor(element) {
        this.element = element;
        this.level = 1;
        this.levels = null;
        this.menuItems = null;
        this.backBtn = null;

        this.init();
    }

    /**
     * Init method
     * Caches Dom
     * Adds data-level attribute to all levels
     * Adds event listeners
     */
    init() {
        this.cacheDOM();

        // set data-level attributes for each of the sub-nav container elements
        this.levels.map((el) => {
            el.setAttribute(ATTRIBUTES.DATA_LEVEL,
                getLevelDepth(el, this.element.id, CLASSES.SUB_NAV_LEVEL)
            );

            return el;
        });

        this.addEventListeners();
    }

    /**
     * Re initialization method
     * Used from header when menu open trigger is clicked
     * Remove open class from all sublevels. Add open and active class to first level
     */
    reInit() {
        this.level = 1;
        this.levels.forEach((level) => {
            level.classList.remove(CLASSES.SUB_NAV_LEVEL_OPEN);
        });
        this.levels[0].classList.add(CLASSES.SUB_NAV_LEVEL_OPEN, CLASSES.SUB_NAV_LEVEL_ACTIVE);
    }

    /**
     * Caches DOM elements
     */
    cacheDOM() {
        // the sub-nav container elements
        this.levels = Array.prototype.slice.call(this.element.querySelectorAll(`.${CLASSES.SUB_NAV_LEVEL}`));
        // sub-nav link elements
        this.menuItems = Array.prototype.slice.call(this.element.querySelectorAll(`.${CLASSES.NAV_ITEM}`));
        // back button element
        this.backBtn = Array.prototype.slice.call(this.element.querySelectorAll(`.${CLASSES.SUB_NAV_BACK_BTN}`));
    }

    /**
     * Adds event listeners
     */
    addEventListeners() {
        // opening a sub level menu
        this.menuItems.forEach((el) => {
            // check if it has a sub level
            const subLevel = el.querySelector(`.${CLASSES.SUB_NAV_LEVEL}`);

            if (subLevel) {
                // Add click listener to the sub-level link
                el.querySelector(`.${CLASSES.SUB_NAV_LEVEL_LINK}`).addEventListener(EVENTS.CLICK, (ev) => {
                    ev.preventDefault();
                    this.openSubMenu(subLevel);
                });
            }

            return el;
        });

        // Back button event listeners
        this.backBtn.forEach((el) => {
            el.addEventListener(EVENTS.CLICK, (ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                this.closeSubMenu();
            });
        });
    }

    /**
     * Closes sub menu
     */
    closeSubMenu() {
        // reduce level depth
        this.level -= 1;

        // Filters out all sub-levels greater than current level, and removes open and active class
        this.levels
            .filter((level) => {
                const foundLevel = level.getAttribute(ATTRIBUTES.DATA_LEVEL) >= (this.level + 1);
                return foundLevel;
            })
            .forEach((level) => {
                level.classList.remove(CLASSES.SUB_NAV_LEVEL_OPEN, CLASSES.SUB_NAV_LEVEL_ACTIVE);
            });

        // add active class to the current level element
        this.levels[this.level - 1].classList.add(CLASSES.SUB_NAV_LEVEL_ACTIVE);
    }

    /**
     * Open submenu
     */
    openSubMenu(subLevel) {
        // remove active class from the last opened level element
        this.levels[this.level - 1].classList.remove(CLASSES.SUB_NAV_LEVEL_ACTIVE);

        // increment level depth
        this.level += 1;

        // add class mp-level-open and active class to the opening level element
        subLevel.classList.add(CLASSES.SUB_NAV_LEVEL_OPEN, CLASSES.SUB_NAV_LEVEL_ACTIVE);
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Import all required modules
import BackToTop from 'partials/BackToTop';
import DeferLoader from 'modules/DeferLoader';
import { Footer, FooterSubscribeForm, FooterSubscribeModal, AccordionLinkList } from 'modules/Footer';
import Header from 'modules/Header';
import GlobalHeader from 'modules/GlobalHeader';
import GlobalHeaderV2 from 'modules/GlobalHeaderV2';
import NavProfileModule from 'modules/NavProfileModule';
import LazyLoader from 'modules/LazyLoader';
import LoadingSpinner from 'partials/loading-spinner';
import HeroYouTube from 'modules/HeroYouTube';
import HTML5VideoControl from 'modules/HTML5VideoControl';
import ShoppingTools from 'modules/ShoppingTools';
import Sticky from 'modules/Sticky';

// Export reference to all modules in an object
export default {
    BackToTop,
    DeferLoader,
    Footer,
    AccordionLinkList,
    FooterSubscribeForm,
    FooterSubscribeModal,
    Header,
    GlobalHeader,
    GlobalHeaderV2,
    NavProfileModule,
    LazyLoader,
    LoadingSpinner,
    HeroYouTube,
    HTML5VideoControl,
    ShoppingTools,
    Sticky,
};
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Util dependencies
import { htmlNode } from 'utils';

/**
 * @description Template for Form Error Message
 * Uses ES6 template literal to composite the markup for a Form Error Message
 */
export default ({ message = '' } = {}) => htmlNode`
    <div class="stay-connected__message" data-analytics-container="{'blade':'newsletter-confirmed'}">
        <p class="stay-connected__error-message">${message}</p>
    </div>
`;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

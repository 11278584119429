// Utils dependencies
import { renderer, parseQueries } from 'utils';

// Partial dependencies
import { Modal } from 'partials/modal';
import footerTemplates from 'partials/footer';
import { InputTextControl } from 'partials/input-control';
import { FormControl } from 'partials/form-control';

const CLASSES = {
    OPEN_MODAL_CTA: 'stay-connected__subscribe-cta',
    PERSONAL_INFORMATION: 'stay-connected-modal__personal-information',
    FORM_ELEMENT: 'stay-connected-modal__form',
    SUBMIT_BUTTON: 'stay-connected-modal__submit',
    ERROR_MESSAGE: 'stay-connected-modal__error-message',
};

const IDS = {
    MODAL_TITLE: 'subscribe-modal-header',
};

const {
    subscribeEmailErrorTryAgain,
    subscribeSubmit,
    subscribeClose,
    subscribeHeader,
    subscribeSubHead,
    subscribeDisclaimer1,
    subscribeDisclaimer2,
    subscribeSuccessHeader,
    subscribeSuccessSubHead,
    formContent,
} = window.mbVans.ns('pageData', 'footerLocalization').modal || {};

const { country, brand } = window.mbVans.pageData;

/**
 * @class StyleGuideRegistrationForm
 * @description Creates a FooterSubscribeForm
 * @param {Object} element
 */
export default class FooterSubscribeForm {
    /**
     * @method constructor
     * @description Instantiates StyleGuideRegistrationForm
     * @param {Node} element
     */
    constructor(element) {
        this.element = element;
        this.submit = this.submit.bind(this);
        this.showErrorResponse = this.showErrorResponse.bind(this);
        this.showSuccessResponse = this.showSuccessResponse.bind(this);
        this.onResponse = this.onResponse.bind(this);
        this.init();
    }

    /**
     * @method init
     * @description Init method
     */
    init() {
        this.includeNames = this.element.dataset.includeNames === 'true';
        this.cacheDOM();
        this.createModal();
        this.handleDeepLink();
    }

    /**
     * @method cacheDOM
     * @description Caches DOM elements
     */
    cacheDOM() {
        this.openModalElm = this.element.querySelector(`.${CLASSES.OPEN_MODAL_CTA}`);
    }

    /**
     * @method handleDeepLink
     * @description If the url includes the parameter subscribe=true, open the modal
    */
    handleDeepLink() {
        const params = parseQueries(window.location);
        if (params.subscribe === 'true') {
            this.modal.open();
        }
    }

    createModal() {
        this.formContainerElement = footerTemplates.SubscribeModalTemplate({
            header: subscribeHeader,
            subhead: subscribeSubHead,
            disclaimer1: subscribeDisclaimer1,
            disclaimer2: subscribeDisclaimer2,
            submit: subscribeSubmit,
        })({ getNode: true });

        this.modal = new Modal(this.openModalElm,
            {
                dataAnalyticTriggerClose: 'cta-subscribe-modal-close',
                modalContent: this.formContainerElement,
                sizeSmall: Modal.SIZES.FULLSCREEN,
                sizeLarge: Modal.SIZES.INSET,
                modalTitleId: IDS.MODAL_TITLE,
            }
        );

        this.personalInfoElement = this.formContainerElement.querySelector(`.${CLASSES.PERSONAL_INFORMATION}`);
        this.submitButton = this.formContainerElement.querySelector(`.${CLASSES.SUBMIT_BUTTON}`);
        this.formElement = this.formContainerElement.querySelector(`.${CLASSES.FORM_ELEMENT}`);
        this.errorMessage = this.formContainerElement.querySelector(`.${CLASSES.ERROR_MESSAGE}`);
        this.createPersonalInfoInputs();
        this.createForm();
    }

    createForm() {
        const formConfig = {
            element: this.formElement,
            onSubmit: this.submit,
        };

        this.form = new FormControl(formConfig);
        this.form.register(this.personalInfoInputs.email);
        if (this.includeNames) {
            this.form.register(this.personalInfoInputs.firstName);
            this.form.register(this.personalInfoInputs.lastName);
        }
        this.formContainerElement.appendChild(this.form.render());
    }

    createPersonalInfoInputs() {
        this.personalInfoInputs = {
            email: new InputTextControl({
                type: 'text',
                required: true,
                labelText: formContent.email,
                maxLength: 50,
                validation: [
                    {
                        type: InputTextControl.VALIDATION_TYPE.REQUIRED,
                        errorMessage: formContent.invalidEmail
                    },
                    {
                        type: InputTextControl.VALIDATION_TYPE.MAX_LENGTH,
                        errorMessage: formContent.invalidEmail
                    },
                    {
                        type: InputTextControl.VALIDATION_TYPE.EMAIL,
                        errorMessage: formContent.invalidEmail
                    }
                ],
                restrictions: [InputTextControl.RESTRICTION_TYPE.EMAIL_SPECIAL_CHARS],
                analyticsTrigger: 'cta-email-address',
            })
        };

        if (this.includeNames) {
            this.personalInfoInputs.firstName = new InputTextControl({
                type: 'text',
                required: true,
                labelText: formContent.firstName,
                maxLength: 50,
                validation: [
                    {
                        type: InputTextControl.VALIDATION_TYPE.REQUIRED,
                        errorMessage: formContent.firstNameBlankError
                    },
                    {
                        type: InputTextControl.VALIDATION_TYPE.MAX_LENGTH,
                        errorMessage: formContent.firstNameBlankError
                    }
                ],
                restrictions: [InputTextControl.RESTRICTION_TYPE.NUMERIC],
                analyticsTrigger: 'cta-first-name',
            });
            this.personalInfoInputs.lastName = new InputTextControl({
                type: 'text',
                required: true,
                labelText: formContent.lastName,
                maxLength: 50,
                validation: [
                    {
                        type: InputTextControl.VALIDATION_TYPE.REQUIRED,
                        errorMessage: formContent.lastNameBlankError
                    },
                    {
                        type: InputTextControl.VALIDATION_TYPE.MAX_LENGTH,
                        errorMessage: formContent.lastNameBlankError
                    }
                ],
                restrictions: [InputTextControl.RESTRICTION_TYPE.NUMERIC],
                analyticsTrigger: 'cta-last-name',
            });
            renderer.append(this.personalInfoInputs.firstName.render(), this.personalInfoElement);
            renderer.append(this.personalInfoInputs.lastName.render(), this.personalInfoElement);
        }
        renderer.append(this.personalInfoInputs.email.render(), this.personalInfoElement);
    }


    /**
     * @method submit
     * @description Submits form
     */
    submit() {
        const eml = encodeURIComponent(this.personalInfoInputs.email.getValue());
        let name = '';
        let lastName = '';

        if (this.includeNames) {
            name = encodeURIComponent(this.personalInfoInputs.firstName.getValue());
            lastName = encodeURIComponent(this.personalInfoInputs.lastName.getValue());
        }

        const subscribeEndpoint = `/content/public-api/forms/preference/submit?api=subscribe&country=${country}&brand=${brand}&emailId=${eml}&firstName=${name}&lastName=${lastName}`;
        const options = {
            headers: {
                'Content-Type': 'application/json',
                'Referrer-Policy': 'origin',
                Referer: window.location.href
            },
            method: 'POST'
        };

        this.submitButton.disabled = true;
        this.removeErrorMessage();

        return fetch(subscribeEndpoint, options).then(this.onResponse)
        .catch(this.showErrorResponse);
    }

    /**
     * @method onResponse
     * @description Handles submission response
     * @param {Object} response Data returned from AJAX request
     */
    onResponse(response) {
        if (response &&
            response.status === 200) {
            this.showSuccessResponse();
        } else {
            this.showErrorResponse();
        }
    }

    /**
     * @method showSuccessResponse
     * @description Shows response for new user
     */
    showSuccessResponse() {
        this.submitButton.disabled = false;
        const element = footerTemplates.SubscribeModalSuccessTemplate({
            header: subscribeSuccessHeader,
            subhead: subscribeSuccessSubHead,
            close: subscribeClose,
        })({ getNode: true });

        const modalContentParent = this.modal.modalContent.parentNode;
        renderer.empty(modalContentParent);
        renderer.append(element, modalContentParent);

        this.submitButton = modalContentParent.querySelector(`.${CLASSES.SUBMIT_BUTTON}`);
        this.submitButton.addEventListener('click', () => this.modal.close());
        this.submitButton.focus();
    }

    /**
     * @method showErrorResponse
     * @description Shows response for error with AJAX request
     */
    showErrorResponse() {
        this.submitButton.disabled = false;
        this.errorMessage.textContent = subscribeEmailErrorTryAgain;
        this.submitButton.focus();
    }

    /**
     * @method removeErrorResponse
     * @description Removes a previous error response
     */
    removeErrorMessage() {
        renderer.empty(this.errorMessage);
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

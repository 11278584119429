import { EVENTS } from 'Constants';

import { YouTube } from 'partials/youtube';
import { Modal } from 'partials/modal';

import { screen } from 'utils';

/**
 * @const CLASSES
 * @description Stores a collection of class names for use in the DOM
 * @type {
 *     YOUTUBE_MODAL_LINK: String,
 *     YOUTUBE_CONTAINER: String,
 *     PLAYING: String
 * }
 */
const CLASSES = {
    YOUTUBE_MODAL_LINK: 'fixed-hero__youtube-modal-opener',
    YOUTUBE_INLINE_LINK: 'fixed-hero__youtube-start',
    YOUTUBE_CONTAINER: 'fixed-hero__youtube-container',
    YOUTUBE_PREVIEW: 'fixed-hero__youtube',
    PLAYING: 'playing'
};

/**
 * @const ARIA_ATTRIBUTES
 * @description constant for the attribute used for accessibility purposes
 */
const ARIA_ATTRIBUTES = {
    ARIA_HIDDEN: 'aria-hidden'
};

/**
 * @class HeroYouTube
 * @description Handles YouTube videos used as backgrounds to heroes
 */
class HeroYouTube {
    /**
     * @method constructor
     * @description Instantiates the HeroYouTube Module
     * @param {Object} element
     */
    constructor(element) {
        this.element = element;
        this.videoId = this.element.dataset.videoId;
        this.cacheDOMElements.bind(this)();

        this.currentScreenState = screen.getState();

        this.openModal = this.openModal.bind(this);
        this.initModal = this.initModal.bind(this);
        this.startVideo = this.startVideo.bind(this);

        this.init();
    }

    /**
     * @method init
     * @description  this method executed exactly after all the properties
     * have been setup by default.
     */
    init() {
        this.videoContainer = this.element.querySelector(`.${CLASSES.YOUTUBE_CONTAINER}`);

        this.attachEvents();

        if (this.currentScreenState.small || this.inlineStarter) {
            this.player = new YouTube(this.videoId, this.videoContainer, (isEnded) => {
                // 'playing' css class controls fading the video and preivew images in/out.
                // Internet Explorer 11 and below do not support a second argument to `toggle`
                // videoModule.classList.toggle('playing', !isEnded);
                this.element.classList[isEnded ? 'remove' : 'add'](CLASSES.PLAYING);
            });

            return this.player;
        }

        return true;
    }

    /**
     * @method attachEvents
     * @description Applies click event listeners to the modal openers when CTAs are present
     */
    attachEvents() {
        if (this.modalOpener) {
            this.modalOpener.addEventListener(EVENTS.CLICK, this.openModal);
        }
        if (this.inlineStarter) {
            this.inlineStarter.addEventListener(EVENTS.CLICK, this.startVideo);
        }
    }

    /**
     * @method openModal
     * @description Open the Modal with the respective options by package
     * @param {Object} currentTarget the clicked opener Element
     */
    openModal() {
        this.videoContainerModal = this.videoPreview.cloneNode(true);
        this.videoContainerModal.setAttribute(ARIA_ATTRIBUTES.ARIA_HIDDEN, false);

        const modal = new Modal(undefined, {
            modalContent: this.videoContainerModal,
            sizeSmall: Modal.SIZES.FULLSCREEN,
            sizeLarge: Modal.SIZES.DEFAULT,
            callbacks: {
                afterOpen: this.initModal
            }
        });

        modal.open();
    }

    /**
     * @method initModal
     * @description Kickstarts the process of populating the modal with a video and related information
     */
    initModal() {
        const player = new YouTube(this.videoId, this.videoContainerModal, (isEnded) => {
            // 'playing' css class controls fading the video and preivew images in/out.
            // Internet Explorer 11 and below do not support a second argument to `toggle`
            // videoModule.classList.toggle('playing', !isEnded);
            this.videoContainerModal.classList[isEnded ? 'remove' : 'add'](CLASSES.PLAYING);
        });

        return player;
    }

    /**
     * @method startVideo
     * @description Starts the video.
     */
    startVideo() {
        if (this.player) {
            this.player.player.playVideo();
        }
    }

    /**
     * @method cacheDOMElements
     * @description caches DOM elements for later use
     */
    cacheDOMElements() {
        this.videoContainer = this.element.querySelector(`.${CLASSES.YOUTUBE_CONTAINER}`);
        this.videoPreview = this.element.querySelector(`.${CLASSES.YOUTUBE_PREVIEW}`);
        this.modalOpener = this.element.querySelector(`.${CLASSES.YOUTUBE_MODAL_LINK}`);
        this.inlineStarter = this.element.querySelector(`.${CLASSES.YOUTUBE_INLINE_LINK}`);
        return this;
    }
}

export default HeroYouTube;
// do not delete 9fbef606107a605d69c0edbcd8029e5d

// Util dependencies
import '@mbusa/now-ui-utils.grid-debugger';
import moduleLoader from '@mbusa/now-ui-utils.module-loader';

// Partial dependencies
import AnalyticsApi from 'partials/analytics';
import { UserProfile } from 'partials/profile';

// Local dependencies
import ModuleMap from './config/ModuleMap';

/**
 * TODO: Update creation of profile once specs have been defined
 * @method createUserProfile
 * @description Creates a user profile instance
 * @return {*}
 */
function createUserProfile() {
    return new UserProfile();
}

function logScreenSize() {
    const analytics = new AnalyticsApi('satellite');
    analytics.logScreenSize();
}

/**
 * The top-level controller for the app responsible for
 * loading other controllers and views.
 */
export default class App {
    /**
     * Initialize all global JS components
     * @returns {void}
     */
    constructor() {
        this.modules = moduleLoader.loadModules(ModuleMap);

        createUserProfile();
        logScreenSize();
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

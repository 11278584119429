// Constant dependencies
import { EVENTS } from 'Constants';

// Local dependencies
import AuthenticationApi from 'partials/authentication/api/AuthenticationApi';

/**
 * @const CLASSES
 * @description helper for the know classes to catch from the DOM
 * @type {Object}
 */
const CLASSES = {
    NAV_PROFILE_CONTAINER_UNAUTHENTICATED: 'nav-profile__container--unauthenticated',
    NAV_PROFILE_CONTAINER_AUTHENTICATED: 'nav-profile__container--authenticated',
    NAV_PROFILE_CONTAINER_ACTIVE: 'nav-profile__container--active',
    NAV_PROFILE_LOGIN: 'nav-profile__login',
    NAV_PROFILE_LOGOUT: 'nav-profile__logout',
    NAV_PROFILE_REGISTER: 'nav-profile__register'
};

const WCM_AUTHOR = window.mbVans.ns('pageData').wcmmode.edit || window.mbVans.ns('pageData').wcmmode.preview;

/**
 * @class NavProfileModule
 * @description determines if user is authenticated or not
 * and handlers login/out/registration click events
 */
export default class NavProfileModule {
    /**
     * @method constructor
     * @description  manage the default values for the inheritance
     */
    constructor(element) {
        this.element = element;
        this.authApi = new AuthenticationApi();
        this.isLoggedIn = this.authApi.isLoggedIn();
        if (!WCM_AUTHOR && this.element) {
            this.init();
        }
    }

    /**
     * @method init
     * @description  initialize the class
     */
    init() {
        this.cacheDOM();
        if ((this.loginButtonElm && this.registerButtonElm) || this.logoutButtonElm) {
            this.setAuthState();
            this.setBindings();
            this.attachEvents();
        }
    }

    /**
     * @method cacheDOM
     * @description  cache the elements required for the class
     */
    cacheDOM() {
        this.navProfileContainerUnauthenticatedElm = this.element.querySelector(`.${CLASSES.NAV_PROFILE_CONTAINER_UNAUTHENTICATED}`);
        this.navProfileContainerAuthenticatedElm = this.element.querySelector(`.${CLASSES.NAV_PROFILE_CONTAINER_AUTHENTICATED}`);
        this.loginButtonElm = this.element.querySelector(`.${CLASSES.NAV_PROFILE_LOGIN}`);
        this.registerButtonElm = this.element.querySelector(`.${CLASSES.NAV_PROFILE_REGISTER}`);
        this.logoutButtonElm = this.element.querySelector(`.${CLASSES.NAV_PROFILE_LOGOUT}`);
    }

    /**
     * @method setBindings
     * @description sets bindings, for proper scoping of event callbacks
     */
    setBindings() {
        this.authLinkClickHandler = this.authLinkClickHandler.bind(this);
        this.registerClickHandler = this.registerClickHandler.bind(this);
        this.logoutClickHandler = this.logoutClickHandler.bind(this);
    }

    /**
     * @method attachEvents
     * @description  add the listeners for the actions required for the class
     */
    attachEvents() {
        if (!this.isLoggedIn) {
            this.loginButtonElm.addEventListener(EVENTS.CLICK, this.authLinkClickHandler);
            this.registerButtonElm.addEventListener(EVENTS.CLICK, this.registerClickHandler);
        } else {
            this.logoutButtonElm.addEventListener(EVENTS.CLICK, this.logoutClickHandler);
        }
    }

    /**
     * @method detachEvents
     * @description  remove all the listeners required for the class
     */
    detachEvents() {
        if (!this.isLoggedIn) {
            this.loginButtonElm.removeEventListener(EVENTS.CLICK, this.authLinkClickHandler);
            this.registerButtonElm.removeEventListener(EVENTS.CLICK, this.registerClickHandler);
        } else {
            this.logoutButtonElm.removeEventListener(EVENTS.CLICK, this.logoutClickHandler);
        }
    }

    /**
     * @method  setAuthState
     * @description  establish the template base in the
     * state for the authenticated user
     */
    setAuthState() {
        if (this.isLoggedIn) {
            this.navProfileContainerAuthenticatedElm.classList.add(CLASSES.NAV_PROFILE_CONTAINER_ACTIVE);
            this.navProfileContainerUnauthenticatedElm.remove();
        } else {
            this.navProfileContainerAuthenticatedElm.remove();
            this.navProfileContainerUnauthenticatedElm.classList.add(CLASSES.NAV_PROFILE_CONTAINER_ACTIVE);
        }
    }

    /**
     * @method authLinkClickHandler
     * @description Callback to apply when the login link is clicked
     * calls action to redirect to login
     */
    authLinkClickHandler() {
        this.authApi.authRedirect(AuthenticationApi.AUTH_PAGES.LOGIN);
    }

    /**
     * @method registerClickHandler
     * @description Callback to apply when the register link is clicked
     * calls action to redirect to register
     */
    registerClickHandler() {
        this.authApi.authRedirect(AuthenticationApi.AUTH_PAGES.REGISTRATION);
    }

    /**
     * @method logoutClickHandler
     * @description Callback to apply when the logout link is clicked
     * calls action to redirect to logout
     */
    logoutClickHandler() {
        this.authApi.authRedirect(AuthenticationApi.AUTH_PAGES.LOGOUT);
    }

    /**
     * @method destroy
     */
    destroy() {
        this.detachEvents();
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d
